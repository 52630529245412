<template>
    <div>
        <div class="row g-2">
            <div class="col-md-6">
                <div class="form-group">
                    <div>
                    <label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
                    <b-form-input v-model="isParent.row.arankaa_keluhan_utama" @input="onInput($event, 'keluhan-utama')" @blur="onBlur($event, 'keluhan-utama')" @focus="onFocus($event, 'keluhan-utama')" :disabled="disabled['keluhan-utama']" id="keluhan_utama" :formatter="isParent.normalText" type="text" name="perKeluhan" class="form-control" placeholder="Keluhan dari pasien" />
                    </div>
                    <small class="text-info">*Mohon tuliskan informasi waktu mulai keluhan terjadi</small><br/>

                    <p class='m-0 text-muted' v-if="disabled['keluhan-utama']">User lain sedang mengisi...</p>

                    <VValidate 
                        name="Keluhan Utama" 
                        v-model="isParent.row.arankaa_keluhan_utama" 
                        :rules="toValidate(isParent.mrValidation.arankaa_keluhan_utama)"
                    />
                </div>

                <div class="form-group">
                    <div>
                    <label for="perRiwayat">Riwayat Penyakit Sekarang</label>
                    <b-form-textarea @input="onInput($event, 'catatan-dokter')" @blur="onBlur($event, 'catatan-dokter')" @focus="onFocus($event, 'catatan-dokter')" :disabled="disabled['catatan-dokter']" id="riwayat_penyakit_sekarang" v-model="isParent.row.arankaa_riwayat_penyakit_sekarang" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Penyakit Sekarang"></b-form-textarea>
                    </div>

                    <p class='m-0 text-muted' v-if="disabled['catatan-dokter']">User lain sedang mengisi...</p>

                    <VValidate 
                        name="Riwayat Penyakit Sekarang" 
                        v-model="isParent.row.arankaa_riwayat_penyakit_sekarang" 
                        :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit_sekarang)"
                    />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <div>
                    <label for="perRiwayat">Riwayat Penyakit Dahulu</label>
                    <b-form-textarea @input="onInput($event, 'riwayat-penyakit-dahulu')" @blur="onBlur($event, 'riwayat-penyakit-dahulu')" @focus="onFocus($event, 'riwayat-penyakit-dahulu')" :disabled="disabled['riwayat-penyakit-dahulu']" id="riwayat" v-model="isParent.row.arankaa_riwayat_penyakit" :formatter="isParent.normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
                    </div>

                    <p class='m-0 text-muted' v-if="disabled['riwayat-penyakit-dahulu']">User lain sedang mengisi...</p>

                    <VValidate 
                        name="Riwayat Penyakit" 
                        v-model="isParent.row.arankaa_riwayat_penyakit" 
                        :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit)"
                    />
                </div>
            </div>
            
            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Pengobatan</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div>
                            <label for="perRiwayat">Riwayat Pengobatan</label>
                            <b-form-textarea id="riwayat_pengobatan" v-model="isParent.row.arankaa_riwayat_pengobatan" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Pengobatan"></b-form-textarea>
                            </div>

                            <span class="text-warning d-none" id="riwayat_pengobatan-typing">User Lain Sedang Mengisi ...</span>            
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                        <label for="perRiwayatGunaObat">Riwayat Penggunaan Obat</label>
                            <table class="table table-sm table-bordered table-input">
                                <thead>
                                <tr>
                                    <th>Nama Obat</th>
                                    <th>Dosis</th>
                                    <th>Waktu Penggunaan</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.arankaa_riwayat_penggunaan_obat||[])" :key="k+'obat'">
                                    <td class="input-cell">
                                    <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['nama']"  :formatter="normalText" type="text" placeholder="Isi Nama Obat" class="form-control form-control-sm" />
                                    <VValidate 
                                        :name="'Nama Obat'+(k+1)" 
                                        v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['nama']" 
                                        :rules="{required: 1, min: 3, max: 128}"
                                    />
                                    </td>
                                    <td class="input-cell">
                                    <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['dosis']" :formatter="normalText" type="text" placeholder="Isi Dosis Obat" class="form-control form-control-sm" />
                                    <VValidate 
                                        :name="'Dosis Obat'+(k+1)" 
                                        v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['dosis']" 
                                        :rules="{required: 1}"
                                    />
                                    </td>
                                    <td class="input-cell">
                                    <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['penggunaan']" :formatter="normalText" type="text" placeholder="Isi Waktu Penggunaan Obat" class="form-control form-control-sm" />
                                    <VValidate 
                                        :name="'Waktu Penggunaan Obat'+(k+1)" 
                                        v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['penggunaan']" 
                                        :rules="{required: 1}"
                                    />
                                    </td>
                                    <td class="input-cell text-center">
                                        <a href="javascript:;" @click="(isParent.row.arankaa_riwayat_penggunaan_obat||[]).splice(k,1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon  text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td class="text-center" colspan="4">
                                    <a href="javascript:;" @click="addRiwayatPenggunaanObat" class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                    </a>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-md-7">
                        <div class="head_panel_red">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3>ALERGI </h3>
                            <div>
                            <b-form-radio-group
                                id="has_alergi"
                                @change="onInput($event, 'has-alergi')"
                                @input="changeAlergi($event)"
                                :options="Config.mr.yesNoOpt"
                                v-model="isParent.row.arankaa_has_alergi"
                            />

                            <span class="text-warning d-none" id="has_alergi-typing">User Lain Sedang Mengisi ...</span>            

                            <VValidate 
                                name="Alergi" 
                                v-model="isParent.row.arankaa_has_alergi" 
                                :rules="toValidate(isParent.mrValidation.arankaa_has_alergi)"
                            />
                            </div>
                        </div>
                        </div>
                        
                        <template v-if="isParent.row.arankaa_has_alergi == 'Y'">
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th width="33%">Jenis</th>
                                    <th>Informasi Alergi</th>
                                    <th width="64"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.arankaa_alergi||[])" :key="k+'aler'">
                                    <td>
                                        <v-select @open="onFocus($event, 'alergi-jenis', k)" @close="onBlur($event, 'alergi-jenis', k)" @input="onInput($event, 'alergi-jenis', k)" :disabled="disabled['alergi-jenis'][k]" placeholder="Pilih Jenis" v-model="isParent.row.arankaa_alergi[k]['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        <p class='m-0 text-muted' v-if="disabled['alergi-jenis'][k]">User lain sedang mengisi...</p>
                                        <VValidate 
                                            :name="'Obat '+(k+1)" 
                                            v-model="isParent.row.arankaa_alergi[k]['jenis']" 
                                            :rules="{required:1}"
                                        />
                                    </td>
                                    <td>
                                        <vue-bootstrap-autocomplete
                                        @focus="onFocus($event, 'alergi-name', k)"
                                        @blur="onBlur($event, 'alergi-name', k)"
                                        v-model="isParent.row.arankaa_alergi[k]['name']"
                                        :data="isParent.mAlergi" @input="searchAlergi(isParent.row.arankaa_alergi[k]['name']);onInput($event, 'alergi-name', k)"
                                        placeholder="Pilih Alergi"
                                        />
                                        
                                        <p class='m-0 text-muted' v-if="disabled['alergi-name'][k]">User lain sedang mengisi...</p>
                                        <VValidate 
                                            :name="'Obat '+(k+1)" 
                                            v-model="isParent.row.arankaa_alergi[k]['name']" 
                                            :rules="{required:1}"
                                        />
                                    </td>
                                    <td>
                                    <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colspan="3" class="text-center">
                                    <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                        </template>
                        <template v-else>
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th width="33%">Jenis</th>
                                    <th>Informasi Alergi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </td>
                                    <td>
                                    <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                    <div class="col-md-5">
                        <div class="head_panel_blue">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3>OBAT RUTIN </h3>
                            <div>
                            <b-form-radio-group
                                id="has_obat_rutin"
                                :options="Config.mr.yesNoOpt"
                                v-model="isParent.row.arankaa_has_obat_rutin"
                            />

                            <span class="text-warning d-none" id="has_obat_rutin-typing">User Lain Sedang Mengisi ...</span>            
                            <VValidate 
                                name="Obat Rutin" 
                                v-model="isParent.row.arankaa_has_obat_rutin" 
                                :rules="toValidate(isParent.mrValidation.arankaa_has_obat_rutin)"
                            />
                            </div>
                        </div>
                        </div>

                        <div class="row mt-2" v-if="isParent.row.arankaa_has_obat_rutin == 'Y'">
                        <div class="col-md-12">
                            <div class="form-group">
                            <label>Keterangan </label>
                            <b-form-textarea id="keterangan_obat_rutin" v-model="isParent.row.arankaa_keterangan_obat_rutin" rows="5" cols="3" class="form-control" placeholder=""
                                spellcheck="false"></b-form-textarea>
                            </div>

                            <span class="text-warning d-none" id="keterangan_obat_rutin-typing">User Lain Sedang Mengisi ...</span>          
                            
                            <VValidate 
                                name="Keterangan" 
                                v-model="isParent.row.arankaa_keterangan_obat_rutin" 
                                :rules="toValidate(isParent.mrValidation.arankaa_keterangan_obat_rutin)"
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Kehamilan Ibu</h6>
                </div>
                
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label for="MasukRuangIntensive">Pemeriksaan Kehamilan Antenatal Care (ANC)<strong
                            class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group
                                :options="isParent.Config.mr.configTeratur"
                                v-model="isParent.row.arankaa_anc"
                            />
                            <VValidate 
                                name="Pemeriksaan Kehamilan" 
                                v-model="isParent.row.arankaa_anc" 
                                :rules="toValidate(isParent.mrValidation.arankaa_anc)"
                            />
                           
                        </div>
                        </div>
                    </div>


                    <div class="col-md-8">
                        <div class="form-group">
                        <label for="MasukRuangIntensive">Tempat pemeriksaan ANC<strong
                            class="text-danger">*</strong></label>
                        <div>
                            <div class="form-check mb-2 form-check-inline align-middle">
                            <b-form-radio-group
                                class="custom-block-2"
                                :options="isParent.Config.mr.configPemeriksaanANC"
                                v-model="isParent.row.arankaa_anc_oleh"
                            />
                           
                            <div class="ml-2 mt-5" v-if="isParent.row.arankaa_anc_oleh == 'Lainnya'">
                                <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_anc_oleh_lainnya"  type="text" class="form-control" placeholder="Lainnya"/>
                                <VValidate 
                                    name="Tempat pemeriksaan Lainnya" 
                                    v-model="isParent.row.arankaa_anc_oleh_lainnya" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_anc_oleh_lainnya)"
                                />
                            </div>
                            </div>
                        </div>
                        <VValidate 
                            name="Tempat pemeriksaan ANC" 
                            v-model="isParent.row.arankaa_anc_oleh" 
                            :rules="toValidate(isParent.mrValidation.arankaa_anc_oleh)"
                        />
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="MasukRuangIntensive">Komplikasi Kehamilan</label>
                            <div>
                                <div>
                              
                                    <b-form-checkbox-group
                                        class="checkbox-inline"
                                        :options="isParent.Config.mr.configKomplikasiANC"
                                        v-model="isParent.row.arankaa_komposisi_kehamilan"
                                    />

                                    <div class="mt-2" v-if="(isParent.row.arankaa_komposisi_kehamilan||[]).indexOf('Alergi') != -1">
                                        <b-form-input style="width: 300px;" :formatter="normalText" v-model="isParent.row.arankaa_komposisi_kehamilan_alergi"  type="text" class="form-control" placeholder="Alergi"/>
                                        <VValidate 
                                            name="Komplikasi Kehamilan Pasien Alergi" 
                                            v-model="isParent.row.arankaa_komposisi_kehamilan_alergi" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_komposisi_kehamilan_alergi)"
                                        />
                                    </div>

                                    <div class="mt-2" v-if="(isParent.row.arankaa_komposisi_kehamilan||[]).indexOf('Lainnya') != -1">
                                        <b-form-input style="width: 300px;" :formatter="normalText" v-model="isParent.row.arankaa_komposisi_kehamilan_lainnya"  type="text" class="form-control" placeholder="Lainnya"/>
                                        <VValidate 
                                            name="Komplikasi Kehamilan Pasien Lainnya" 
                                            v-model="isParent.row.arankaa_komposisi_kehamilan_lainnya" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_komposisi_kehamilan_lainnya)"
                                        />
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="form-group">
                            <h6 class="card-title font-weight-semibold">Riwayat Kehamilan Ibu</h6>
                            
                            <div class="row">
                                <div class="col-md-12">
                                        
                                    <label for="MasukRuangIntensive">Cara Persalinan<strong
                                    class="text-danger">*</strong></label><br/>

                                    <div class="form-check mb-2 form-check-inline align-middle">
                                    <b-form-radio-group
                                        :options="isParent.Config.mr.configPengobatanIbu"
                                        v-model="isParent.row.arankaa_riwayat_pengobatan_ibu"
                                    />
                                
                                    <div class="ml-2" v-if="isParent.row.arankaa_riwayat_pengobatan_ibu == 'Lainnya'">
                                        <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_riwayat_pengobatan_ibu_lainnya"  type="text" class="form-control" placeholder="Lainnya"/>
                                        <VValidate 
                                            name="Cara Persalinan Lainnya" 
                                            v-model="isParent.row.arankaa_riwayat_pengobatan_ibu_lainnya" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_riwayat_pengobatan_ibu_lainnya)"
                                        />
                                    </div>
                                    </div>
                                    

                                </div>
                                
                                <VValidate 
                                    name="Cara Persalinan ANC" 
                                    v-model="isParent.row.arankaa_riwayat_pengobatan_ibu" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_riwayat_pengobatan_ibu)"
                                />
                            </div>

                            <div class="row">
                                <label for="MasukRuangIntensive" class="col-md-12">Status Kehamilan<strong
                                    class="text-danger">*</strong></label>
                                <div class="col-md-4">
                                    <div>
                                    <label for="perGravid">Gravid<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="number" v-model="isParent.row.arankaa_gravid"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Gravid" 
                                        v-model="isParent.row.arankaa_gravid" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_gravid)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label for="perPara">Para<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="number" v-model="isParent.row.arankaa_para"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Para" 
                                        v-model="isParent.row.arankaa_para" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_para)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label for="perAbortus">Abortus<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="number" v-model="isParent.row.arankaa_abortus"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Abortus" 
                                        v-model="isParent.row.arankaa_abortus" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_abortus)"
                                    />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <label for="MasukRuangIntensive" class="col-md-12">Riwayat Persalinan yang Lalu<strong
                                    class="text-danger">*</strong></label>
                                <div class="col-md-4">
                                    <div>
                                    <label>Komplikasi Persalinan<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_komplikasi_persalinan_text"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Komplikasi Persalinan" 
                                        v-model="isParent.row.arankaa_komplikasi_persalinan_text" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_komplikasi_persalinan_text)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label>Kondisi Persalinan<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_kondisi_persalinan_text"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Kondisi Persalinan" 
                                        v-model="isParent.row.arankaa_kondisi_persalinan_text" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_kondisi_persalinan_text)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label>Kondisi Riwayat Saat Ini<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_kondisi_riwayat_saat_ini"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Kondisi Riwayat Saat Ini" 
                                        v-model="isParent.row.arankaa_kondisi_riwayat_saat_ini" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_kondisi_riwayat_saat_ini)"
                                    />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!--
                    <div class="col-12">
                    <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Identifikasi Bayi</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">

                            <div class="col-md-3">
                            <div class="form-group">
                                <label>Nama Ibu</label>
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_ibu_nama" :formatter="isParent.normalText" type="text" name="perKeluhan" class="form-control" placeholder="Nama Ibu" />
                            </div>
                            </div>

                            <div class="col-md-2">
                            <div class="form-group">
                                <label>Umur Ibu</label>
                                <div class="input-group">
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_ibu_umur" :formatter="isParent.numberOnly" type="text" name="perKeluhan" class="form-control" placeholder="Umur Ibu" />
                                <div class="input-group-append"><span class="input-group-text">Tahun</span>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label>Nama Ayah</label>
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_ayah_nama" :formatter="isParent.normalText" type="text" name="perKeluhan" class="form-control" placeholder="Nama Ayah" />
                            </div>
                            </div>
                    
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                <label for="perHamil">Agama Orang Tua</label>
                                <v-select placeholder="Pilih Agama" v-model="isParent.row.arankaa_identifikasi_agama_ortu" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <VValidate 
                                    name="Agama Orang Tua" 
                                    v-model="isParent.row.arankaa_identifikasi_agama_ortu" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>
                            <div class="col-md-3" v-if="isParent.row.arankaa_identifikasi_agama_ortu  == 99999">
                                <div class="form-group">
                                <label for="perHamil">Agama Orang Tua Lainnya<strong class="text-danger">*</strong></label>
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_agama_ortu_text" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Agama Orang Tua Lainnya" />
                                <VValidate 
                                    name="Agama Orang Tua Lainnya" 
                                    v-model="isParent.row.arankaa_identifikasi_agama_ortu_text" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>

                            <div class="col-md-6">
                            <div>
                                <label for="perRiwayatPengobatan">Diagnosa Medis<strong
                                    class="text-danger">*</strong></label>
                                <textarea v-model="isParent.row.arankaa_identifikasi_diagnosa_medis" name="perRiwayatPengobatan" id="perRiwayatPengobatan" rows="2"
                                class="form-control" placeholder="Diagnosa Medis"></textarea>

                                <VValidate 
                                    name="Diagnosa Medis" 
                                    v-model="isParent.row.arankaa_identifikasi_diagnosa_medis" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_identifikasi_diagnosa_medis)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-12">
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Riwayat Sosial & Spiritual</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <h6 class="font-weight-semibold text-indigo">Hubungan orang Tua dan bayi (tingkah laku): </h6>
                            </div>
                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Ibu<strong class="text-danger">*</strong></label>
                                <div>
                                  <b-form-group>
                                      <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="isParent.row.arankaa_hub_ibu"
                                        :options="Config.mr.mHubunganRanap"
                                      ></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Ayah<strong class="text-danger">*</strong></label>
                                <div>
                                  <b-form-group>
                                      <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="isParent.row.arankaa_hub_ayah"
                                        :options="Config.mr.mHubunganRanap"
                                      ></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-12">
                            <hr>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Asuhan Terminal</label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_asuhan_terminal"
                                  />
                                </div>
                            </div>
                            </div>
                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Kebutuhan Spiritual keluarga pasien dalam perawatan di
                                Rumah Sakit</label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.mRKDiperlukanDlmHal"
                                      v-model="isParent.row.arankaa_kebutuhan_spiritual"
                                  />
                                </div>
                            </div>
                            </div>

                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Keinginan keluarga untuk DNR terhadap pasien</label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_keinginan_dnr"
                                  />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    -->

                    <!--
                    <div class="col-12">
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Rencana Kepulangan</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <h6 class="font-weight-semibold text-indigo">Pengaruh rawat inap terhadap: </h6>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Pasien dan keluarga pasien<strong
                                    class="text-danger">*</strong></label>
                                <b-form-radio-group
                                    :options="isParent.Config.mr.yesNoOpt"
                                    v-model="isParent.row.arankaa_rk_pasien_keluarga"
                                />
                                <VValidate 
                                  name="Pasien dan keluarga pasien" 
                                  v-model="isParent.row.arankaa_rk_pasien_keluarga" 
                                  :rules="toValidate(isParent.mrValidation.arankaa_rk_pasien_keluarga)"
                                />
                            </div>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Pekerjaan<strong class="text-danger">*</strong></label>
                                <div>
                                
                                <b-form-radio-group
                                    :options="isParent.Config.mr.yesNoOpt"
                                    v-model="isParent.row.arankaa_rk_pekerjaan"
                                />
                                <VValidate 
                                  name="Penagruh Pekerjaan" 
                                  v-model="isParent.row.arankaa_rk_pekerjaan" 
                                  :rules="toValidate(isParent.mrValidation.arankaa_rk_pekerjaan)"
                                />

                                </div>
                            </div>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Keuangan<strong class="text-danger">*</strong></label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_rk_keuangan"
                                  />
                                  <VValidate 
                                    name="Keuangan" 
                                    v-model="isParent.row.arankaa_rk_keuangan" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_rk_keuangan)"
                                  />
                                </div>
                            </div>
                            </div>


                            <div class="col-md-12">
                            <hr>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Antisipasi terhadap masalah saat pulang<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_rk_antisipasi"
                                  />
                                  <VValidate 
                                    name="Antisipasi terhadap masalah saat pulang" 
                                    v-model="isParent.row.arankaa_rk_antisipasi" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_rk_antisipasi)"
                                  />
                                </div>
                            </div>
                            </div>




                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Bantuan diperlukan dalam hal<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                  <b-form-group>
                                      <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="isParent.row.arankaa_bantuan"
                                        :options="Config.mr.mBantuanDiperlukanDalamHal"
                                      ></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                            </div>
                            </div>



                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Adakah yang membantu keperluan tersebut diatas? <strong
                                    class="text-danger">*</strong></label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_keperluan_diatas"
                                  />
                                  <VValidate 
                                    name="Adakah yang membantu keperluan tersebut diatas?" 
                                    v-model="isParent.row.arankaa_keperluan_diatas" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_keperluan_diatas)"
                                  />
                                </div>
                                <div class="mt-2" v-if="isParent.row.arankaa_keperluan_diatas == 'Y'">
                                  <b-form-input v-model="isParent.row.arankaa_keperluan_diatas_text" :formatter="isParent.normalText" type="text" class="form-control" placeholder="Sebutkan" />
                                  <VValidate 
                                    name="Pembantu Keperluan" 
                                    v-model="isParent.row.arankaa_keperluan_diatas_text" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_keperluan_diatas_text)"
                                  />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    -->

                    </div>
                </div>

                </div>
            </div>
    
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                    </div> 
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Tekanan Darah</label>
                            <div class="input-group">
                                <b-form-input id="tekanan_darah_min" @blur="onBlur($event, 'tekanan-darah-min')" @focus="onFocus($event, 'tekanan-darah-min')" @input="onInput($event, 'tekanan-darah-min');isParent.autofill($event,'arankkp_ttv_tekanan_darah_min')" :disabled="disabled['tekanan-darah-min']"
                                :formatter="number" v-model="isParent.row.arankaa_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                <b-form-input id="tekanan_darah_max" @blur="onBlur($event, 'tekanan-darah-max')" @focus="onFocus($event, 'tekanan-darah-max')" @input="onInput($event, 'tekanan-darah-max');isParent.autofill($event,'arankkp_ttv_tekanan_darah_max')" :disabled="disabled['tekanan-darah-max']" :formatter="number" placeholder="Diastole" v-model="isParent.row.arankaa_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>
                            
                            <p class='m-0 text-muted' v-if="disabled['tekanan-darah-min'] || disabled['tekanan-darah-max']">User lain sedang mengisi...</p>

                            <VValidate 
                                name="Tekanan Darah Min" 
                                v-model="isParent.row.arankaa_ttv_tekanan_darah_min" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_tekanan_darah_min)"
                            />
                            <VValidate 
                                name="Tekanan Darah Max" 
                                v-model="isParent.row.arankaa_ttv_tekanan_darah_max" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_tekanan_darah_max)"
                            />
                            </div>
                        </div>

                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Nadi <span class="text-danger">*</span></label>
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <div class="input-group">
                                        <b-form-input id="nadi" @blur="onBlur($event, 'nadi')" @focus="onFocus($event, 'nadi')" @input="onInput($event, 'nadi');isParent.autofill($event,'arankkp_ttv_nadi')" :disabled="disabled['nadi']" :formatter="number" v-model="isParent.row.arankaa_ttv_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                        <div class="input-group-append">
                                            <div style="width: 140px;">
                                            <v-select id="label" @close="onBlur($event, 'nadi-label')" @open="onFocus($event, 'nadi-label')" @input="onInput($event, 'nadi-label');isParent.autofill($event,'arankkp_ttv_label')" :disabled="disabled['nadi-label']" placeholder="Pilih Label" v-model="isParent.row.arankaa_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <p class='m-0 text-muted' v-if="disabled['nadi'] || disabled['nadi-label']">User lain sedang mengisi...</p>                                        

                                <VValidate 
                                    name="Nadi" 
                                    v-model="isParent.row.arankaa_ttv_nadi" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_ttv_nadi)"
                                />
                                <VValidate 
                                    name="Label" 
                                    v-model="isParent.row.arankaa_ttv_label" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_ttv_label)"
                                />
                            </div>
                        </div>
                        
                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Gula Darah</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="gula_darah" @blur="onBlur($event, 'gula-darah')" @focus="onFocus($event, 'gula-darah')" @input="onInput($event, 'gula-darah');isParent.autofill($event,'arankkp_ttv_gula_darah')" :disabled="disabled['gula-darah']" :formatter="alphanum" v-model="isParent.row.arankaa_ttv_gula_darah"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['gula-darah']">User lain sedang mengisi...</p>
                            
                            <VValidate 
                                name="Gula Darah" 
                                v-model="isParent.row.arankaa_ttv_gula_darah" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_gula_darah)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Pernafasan<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <b-form-input id="pernafasan" @blur="onBlur($event, 'pernafasan')" @focus="onFocus($event, 'pernafasan')" @input="onInput($event, 'pernafasan');isParent.autofill($event,'arankkp_ttv_pernafasan')" :disabled="disabled['pernafasan']" :formatter="number" v-model="isParent.row.arankaa_ttv_pernafasan"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                </div>

                                <p class='m-0 text-muted' v-if="disabled['pernafasan']">User lain sedang mengisi...</p>

                                <VValidate 
                                    name="Pernafasan" 
                                    v-model="isParent.row.arankaa_ttv_pernafasan" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_ttv_pernafasan)"
                                />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="spo2" @blur="onBlur($event, 'spo2')" @focus="onFocus($event, 'spo2')" @input="onInput($event, 'spo2');isParent.autofill($event,'arankkp_ttv_spo2')" :disabled="disabled['spo2']" :formatter="number" v-model="isParent.row.arankaa_ttv_spo2"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <p class='m-0 text-muted' v-if="disabled['spo2']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="SPO2" 
                                v-model="isParent.row.arankaa_ttv_spo2" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_spo2)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu<span class="text-danger">*</span></label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="suhu" @blur="onBlur($event, 'suhu')" @focus="onFocus($event, 'suhu')" @input="onInput($event, 'suhu');isParent.autofill($event,'arankkp_ttv_suhu')" :disabled="disabled['suhu']" :formatter="number" v-model="isParent.row.arankaa_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['suhu']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="Suhu" 
                                v-model="isParent.row.arankaa_ttv_suhu" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_suhu)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="weight" @blur="onBlur($event, 'berat-badan')" @focus="onFocus($event, 'berat-badan')" :disabled="disabled['berat-badan']" @input="hitungBMI();onInput($event, 'berat-badan');isParent.autofill($event,'arankkp_ttv_weight')" :formatter="number" v-model="isParent.row.arankaa_ttv_weight"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['berat-badan']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="Berat Badan" 
                                v-model="isParent.row.arankaa_ttv_weight" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_weight)"
                            />
                            </div>
                        </div>

                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Tinggi</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="height" @blur="onBlur($event, 'tinggi')" @focus="onFocus($event, 'tinggi')" :disabled="disabled['tinggi']" @input="hitungBMI();onInput($event, 'tinggi');isParent.autofill($event,'arankkp_ttv_height')" :formatter="number" v-model="isParent.row.arankaa_ttv_height"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['tinggi']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="Tinggi Badan" 
                                v-model="isParent.row.arankaa_ttv_height" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_height)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Lingkar Kepala</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="lingkar_kepala" @blur="onBlur($event, 'lingkar-kepala')" @focus="onFocus($event, 'lingkar-kepala')" :disabled="disabled['lingkar-kepala']" @input="hitungBMI();onInput($event, 'lingkar-kepala');isParent.autofill($event,'arankkp_ttv_lingkar_kepala')"
                                    
                                    :formatter="number" v-model="isParent.row.arankaa_ttv_lingkar_kepala"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['lingkar-kepala']">User lain sedang mengisi...</p>

                            <VValidate 
                                name="Lingkar Kepala" 
                                v-model="isParent.row.arankaa_ttv_lingkar_kepala" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_lingkar_kepala)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>BMI</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input disabled v-model="isParent.row.arankaa_ttv_bmi"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">m2</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                            <div class="form-group">
                            <label>Luas Permukaan Tubuh Anak</label>
                            <div class="input-group">
                                <b-form-input id="permukaan_anak" @blur="onBlur($event, 'permukaan-anak')" @focus="onFocus($event, 'permukaan-anak')" @input="onInput($event, 'permukaan-anak');isParent.autofill($event,'arankkp_ttv_luas_permukaan_anak')" :disabled="disabled['permukaan-anak']" :formatter="number" v-model="isParent.row.arankaa_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                            </div>
                            </div>
                            
                            <p class='m-0 text-muted' v-if="disabled['permukaan-anak']">User lain sedang mengisi...</p>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Kesadaran</label>
                            <button type="button" @click="isOpenTS=true" class="btn btn-light mr-3">Isi Tingkat Kesadaran</button>
                            <template v-if="isParent.row.aranktk_nilai_gcs">
                                <div class="">
                                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </div>
                                
                                <div class="">
                                    <span class="font-weight-semibold mr-3">E : {{isParent.row.aranktk_response_mata||"-"}}</span>
                                    <span class="font-weight-semibold mr-3">M : {{isParent.row.aranktk_response_motorik||"-"}}</span>
                                    <span class="font-weight-semibold mr-3">V : {{isParent.row.aranktk_response_verbal||"-"}}</span>
                                </div>
                            </template>
                            
                            <VValidate 
                                name="Kesadaran" 
                                v-model="isParent.row.aranktk_nilai_gcs" 
                                :rules="{required : 1}"
                            />
                            </div>
                        </div>

                        <!--
                        <div class="col-md-3" v-if="!isParent.row.arankaa_ttv_bmi">
                            <div class="form-group">
                                <label>IMT</label>
                                <b-form-input v-model="isParent.row.arankaa_ttv_imt" type="text"
                                class="form-control" />
                            </div>
                        </div>
                        -->

                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Aspek Psiko Sosial Spiritual</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-3">
                            <div class="signsCheck">
                                <h6 class="font-weight-semibold">Komunikasi</h6>
                                <b-form-group>
                                    <b-form-checkbox-group
                                        class="checkbox-block"
                                        v-model="isParent.row.arankaa_aspek_komunikasi"
                                        :options="isParent.mAspekKomunikasi"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="signsCheck">
                                <h6 class="font-weight-semibold">Toleransi/Koping Terhadap Stress</h6>
                                <b-form-group>
                                    <b-form-checkbox-group
                                        class="checkbox-block"
                                        v-model="isParent.row.arankaa_aspek_toleransi"
                                        :options="isParent.mAspekToleransi"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="signsCheck">
                                <h6 class="font-weight-semibold">Dikaji pada pasien atau keluarga</h6>
                                <div class="form-group">
                                    <label for="kkeBimbinganRohani">Bimbingan Rohani? <span style="cursor:help;"
                                    v-b-tooltip.hover 
                                    title="Apakah pasien memerlukan pelayanan/bimbingan rohani selama dirawat?"><i
                                        class="icon-info22 text-indigo"></i></span></label>
                                    <b-form-radio-group
                                        :options="isParent.Config.mr.yesNoOpt"
                                        v-model="isParent.row.arankkp_has_bimbingan_rohani"
                                    />
                                </div>
                                <template v-if="isParent.row.arankkp_has_bimbingan_rohani == 'Y'">
                                <label for="perHamil">Bila Ya, Rohaniwan apa yang anda butuhkan<strong
                                    class="text-danger">*</strong></label>
                                    <div class="form-group">
                                    <v-select placeholder="Pilih Rohaniawan" v-model="isParent.row.arankkp_rohanian" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    <VValidate 
                                        name="Rohaniawan Pasien" 
                                        v-model="isParent.row.arankkp_rohanian" 
                                        :rules="{required: 1}"
                                    />
                                    </div>
                                </template>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            
            -->
            <div class="col-12" v-if="(isParent.row.ap_gender == 2 || isParent.row.ap_gender == 0) && isParent.row.ap_usia >= 12 && isParent.row.ap_usia <= 60">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row gx-6">
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div>
                        <label for="perHamil">Pasien Sedang Hamil?<strong class="text-danger">*</strong></label>
                        
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankaa_is_hamil"
                        />
                        <VValidate 
                            name="Pasien Hamil" 
                            v-model="isParent.row.arankaa_is_hamil" 
                            :rules="toValidate(isParent.mrValidation.arankaa_is_hamil)"
                        />

                        </div>
                        <div class="mt-3">
                        <label for="perMenyusui">Pasien Menyusui?<strong class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.arankaa_is_menyusui"
                            />
                            <VValidate 
                                name="Pasien Menyusui" 
                                v-model="isParent.row.arankaa_is_menyusui" 
                                :rules="toValidate(isParent.mrValidation.arankaa_is_menyusui)"
                            />
                        </div>
                        </div>
                    </div>
                
                    <div class="col-md border-left" v-if="isParent.row.arankaa_is_hamil == 'Y'">
                        <div class="row g-2 mb-2">
                            <div class="col-md-8">
                                <label for="perRiwayat">Tinggi Fundus Uteri</label>
                                <b-form-textarea  v-model="isParent.row.arankaa_tinggi_fungus" :formatter="normalText"  name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Tinggi Fundus Uteri"></b-form-textarea>
                            </div>
                        </div>
                
                        <div class="row g-2">
                        <div class="col-md-4">
                            <div>
                            <label for="perGravid">Gravid<strong class="text-danger">*</strong></label>
                            <b-form-input :formatter="number" v-model="isParent.row.arankaa_gravid"  type="text" class="form-control" />
                            <VValidate 
                                name="Gravid" 
                                v-model="isParent.row.arankaa_gravid" 
                                :rules="toValidate(isParent.mrValidation.arankaa_gravid)"
                            />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                            <label for="perPara">Para<strong class="text-danger">*</strong></label>
                            <b-form-input :formatter="number" v-model="isParent.row.arankaa_para"  type="text" class="form-control" />
                            <VValidate 
                                name="Para" 
                                v-model="isParent.row.arankaa_para" 
                                :rules="toValidate(isParent.mrValidation.arankaa_para)"
                            />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                            <label for="perAbortus">Abortus<strong class="text-danger">*</strong></label>
                            <b-form-input :formatter="number" v-model="isParent.row.arankaa_abortus"  type="text" class="form-control" />
                            <VValidate 
                                name="Para" 
                                v-model="isParent.row.arankaa_abortus" 
                                :rules="toValidate(isParent.mrValidation.arankaa_abortus)"
                            />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <label for="perHpht">HPHT</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Pilih Tanggal" @input="changeHPL" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.arankaa_hpht" >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                            <label for="perHpl">HPL</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal HPL" disabled class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.arankaa_hpl" >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                            <VValidate 
                                name="HPL" 
                                v-model="isParent.row.arankaa_hpl" 
                                :rules="toValidate(isParent.mrValidation.arankaa_hpl)"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Hasil Pemeriksaan Lab</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilLab||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilLab||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-md-12 mt-3">
                            <h6>Hasil Pemeriksaan Radiologi</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilRadiologi||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilRadiologi||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <h6>Hasil Pemeriksaan Lab Dokter</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilLabDokter||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilLabDokter||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-md-12 mt-3">
                            <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilRadiologiDokter||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="col-md-6">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Psikologi</h6>
            </div>
            <div class="card-body p-3">
                <div>
                <label for="perHub">Status Psikologis Pasien<strong class="text-danger">*</strong></label>
                <div>
                    <b-form-radio
                        v-for="v in isParent.Config.mr.psikologiPot"
                        inline
                        class="mb-1"
                        v-model="isParent.row.arankaa_status_psikologi"
                        :key="v.value"
                        :value="v.value"
                        @input="inputPsikolog($event)"
                    >{{ v.text }}</b-form-radio>
                    <div class="mt-2" v-if="isParent.row.arankaa_status_psikologi == 6">
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_status_psikologi_lainnya"  type="text" class="form-control" placeholder="Isi psikologis pasien"/>
                        <VValidate 
                            name="Psikologi Pasien Lainnya" 
                            v-model="isParent.row.arankaa_status_psikologi_lainnya" 
                            :rules="toValidate(isParent.mrValidation.arankaa_status_psikologi_lainnya)"
                        />
                    </div>
                    
                    <VValidate 
                        name="Psikologi Pasien" 
                        v-model="isParent.row.arankaa_status_psikologi" 
                        :rules="toValidate(isParent.mrValidation.arankaa_status_psikologi)"
                    />
                </div>
                </div>
                <div class="row">
                <div class="col-md-6">
                    <div class="mt-3">
                    <label for="kkeStatusMental">Status Mental</label>
                    <div>
                        <b-form-group>
                            <b-form-checkbox-group
                                v-model="isParent.row.arankaa_status_mental"
                                :options="Config.mr.ranapStatusMental"
                                name="bahasa"
                            ></b-form-checkbox-group>
                        </b-form-group>
                        
                        <VValidate 
                            name="Status Mental Pasien" 
                            v-model="isParent.row.arankaa_status_mental" 
                            :rules="toValidate(isParent.mrValidation.arankaa_status_mental)"
                        />
                    </div>
                    </div>
                </div>
                <div class="col-md-6">
                
                    <div class="mt-3" v-if="(isParent.row.arankaa_status_mental||[]).indexOf('Ada kelainan perilaku, sebutkan') != -1">
                    <label for="kkePerilakuKekerasan">Kelainan Perilaku</label>
                    <b-form-textarea v-model="isParent.row.arankaa_kelainan_perilaku" :formatter="normalText"  name="perRiwayat" class="form-control" 
                    placeholder="Kelainan perilaku"></b-form-textarea>                    
                    </div>

                    <div class="mt-3" v-if="(isParent.row.arankaa_status_mental||[]).indexOf('Ada perilaku kekerasan yang dialami pasien sebelumnya') != -1">
                    <label for="kkePerilakuKekerasan">Perilaku kekerasan yang dialami pasien
                        sebelumnya</label>
                    
                    <b-form-textarea v-model="isParent.row.arankaa_perilaku_kekerasan" :formatter="normalText"  name="perRiwayat" class="form-control" 
                    placeholder="Perilaku kekerasan yang dialami pasien sebelumnya"></b-form-textarea>
                    
                    </div>
                </div>
                </div>


            </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card mb-0 h-100">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Sosial Ekonomi</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                <div class="col-md-6">
                    <div>
                        <label for="perHamil">Pendidikan Pasien<strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih Pendidikan" v-model="isParent.row.arankaa_pendidikan" :options="isParent.mPendidikan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    
                    <VValidate 
                        name="Pendidikan Pasien" 
                        v-model="isParent.row.arankaa_pendidikan" 
                        :rules="{required: 1}"
                    />

                </div>
                <div class="col-md-6 col-lg-6">
                    <div>
                        <label for="perHamil">Pekerjaan Pasien<strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.arankaa_pekerjaan" :options="isParent.mPekerjaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    
                    <VValidate 
                        name="Pekerjaan Pasien" 
                        v-model="isParent.row.arankaa_pekerjaan" 
                        :rules="{required: 1}"
                    />

                    <template v-if="isParent.row.arankaa_pekerjaan == 99999">
                        <label class="mt-2" for="perHamil">Pekerjaan Lainnya<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_pekerjaan_lainnya"  type="text" class="form-control" placeholder="Isi Pekerjaan Lainnya"/>
                        <VValidate 
                            name="Isi Pekerjaan Lainnya" 
                            v-model="isParent.row.arankaa_pekerjaan_lainnya" 
                            :rules="{required: 1, min: 2}"
                        />
                    </template>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Spiritual</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                
                <div class="col-md-3">
                    <div class="form-group">
                    <label for="perHamil">Agama Pasien<strong class="text-danger">*</strong></label>
                    <v-select placeholder="Pilih Agama" v-model="isParent.row.arankaa_agama" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    <VValidate 
                        name="Agama Pasien" 
                        v-model="isParent.row.arankaa_agama" 
                        :rules="{required: 1}"
                    />
                    </div>
                </div>
                <div class="col-md-3" v-if="isParent.row.arankaa_agama  == 99999">
                    <div class="form-group">
                    <label for="perHamil">Agama Pasien Lainnya<strong class="text-danger">*</strong></label>
                    <b-form-input v-model="isParent.row.arankaa_agama_lainnya" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Agama Pasien Lainnya" />
                    <VValidate 
                        name="Agama Pasien Lainnya" 
                        v-model="isParent.row.arankaa_agama_lainnya" 
                        :rules="{required: 1}"
                    />
                    </div>
                </div>

                <div class="w-100"></div>
                <div class="col-md-5">
                    <div class="form-group">
                    <label for="spiKeyakinan">Keyakinan</label>
                    <b-form-textarea :formatter="normalText" v-model="isParent.row.arankaa_keyakinan" name="spiKeyakinan" id="spiKeyakinan" rows="3" class="form-control"></b-form-textarea>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                    <label for="spiNilai">Nilai-nilai Pasien atau Keluarga</label>
                    <b-form-textarea :formatter="normalText" v-model="isParent.row.arankaa_nilai" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Nutrisi</h6>
            </div>
            <div class="card-body p-3">
                <div class="row gy-2">
                <div class="col-md-4">
                    <div>
                    <label for="intakeNutrisi">Intake nutrisi lewat<span class="text-danger">*</span></label>
                
                    <b-form-checkbox-group class="checkbox-block"
                        v-model="isParent.row.arankkp_intake_nutrisi"
                        :options="isParent.Config.mr.mediaTerpasangObsgyn" 
                        label="text" :clearable="true" :reduce="v=>v.value"
                        name="intake_nutrisi"
                    ></b-form-checkbox-group>
                    
                    <VValidate 
                        name="Intake nutrisi lewat" 
                        v-model="isParent.row.arankkp_intake_nutrisi" 
                        :rules="{required: 1}"
                    />
                    </div>
                </div>
                <div class="col-md-8">
                    <div>
                    <label for="masalahNutrisi">Masalah yang berhubungan dengan nutrisi<span class="text-danger">*</span></label>
                    <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.arankkp_nutrisi_obesitas" :options="isParent.Config.mr.ranapMasalahNutrisi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    
                    <VValidate 
                        name="Masalah yang berhubungan dengan nutrisi" 
                        v-model="isParent.row.arankkp_nutrisi_obesitas" 
                        :rules="{required: 1}"
                    />

                    </div>
                </div>
                <template v-if="(isParent.row.arankkp_intake_nutrisi||[]).indexOf('L') != -1">
                    <div class="col-md-4">
                    <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_intake_nutrisi_text"  type="text" class="form-control" placeholder="Sebutkan"/>
                    <VValidate 
                        name="Intake nutrisi lewat Lainnya" 
                        v-model="isParent.row.arankkp_intake_nutrisi_text" 
                        :rules="{required: 1, min: 2}"
                    />
                    </div>
                </template>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-md-6">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Elimnasi</h6>
            </div>
            <div class="card-body p-3">
                <h6 class="font-weight-semibold text-indigo">BAB<span class="text-danger">*</span></h6>
                <div class="row gy-2">
                <div class="col-md-6">
                    <div>
                    <label for="kondisiBab">Kondisi<span class="text-danger">*</span></label>
                    <v-select placeholder="Pilih Kondisi" v-model="isParent.row.arankkp_bab_kondisi" :options="isParent.Config.mr.ranapMasalahBAB" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    <VValidate 
                        name="Kondisi BAB" 
                        v-model="isParent.row.arankkp_bab_kondisi" 
                        :rules="{required: 1}"
                    />
                </div>
                <div class="col-md-6">
                    <div>
                    <label for="kondisiBab">Frekuensi BAB / Hari<span class="text-danger">*</span></label>
                    <b-form-input v-model="isParent.row.arankkp_bab_frekuensi" :formatter="normalText" type="text" name="perKeluhan" class="form-control"/>
                    </div>
                    <VValidate 
                        name="Frekuensi BAB / Hari" 
                        v-model="isParent.row.arankkp_bab_frekuensi" 
                        :rules="{required: 1}"
                    />
                </div>
                <template v-if="isParent.row.arankkp_bab_kondisi == 'L'">
                    <div class="col-md-6">
                    <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_bab_kondisi_text"  type="text" class="form-control" placeholder="Sebutkan"/>
                    <VValidate 
                        name="Bab Lainnya" 
                        v-model="isParent.row.arankkp_bab_kondisi_text" 
                        :rules="{required: 1, min: 2}"
                    />
                    </div>
                </template>
                </div>
            </div>
            <div class="card-body p-3">
                <h6 class="font-weight-semibold text-indigo">BAK</h6>
                <div class="row gy-2">
                <div class="col-md-6">
                    <div>
                    <label for="bakKondisi">Kondisi<span class="text-danger">*</span></label>
                    <v-select placeholder="Pilih Kondisi" v-model="isParent.row.arankkp_bak_kondisi" :options="isParent.Config.mr.ranapMasalahBAK" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    <VValidate 
                        name="Kondisi BAK" 
                        v-model="isParent.row.arankkp_bak_kondisi" 
                        :rules="{required: 1}"
                    />
                </div>
                <div class="col-md-6">
                    <div>
                    <label for="bakKateter">Kateter<span class="text-danger">*</span></label>
                    
                    <v-select placeholder="Pilih Kondisi" v-model="isParent.row.arankkp_bak_frekuensi" :options="isParent.Config.mr.ranapMasalahKateter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    <VValidate 
                        name="Kateter" 
                        v-model="isParent.row.arankkp_bak_frekuensi" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <template v-if="isParent.row.arankkp_bak_kondisi == 'L'">
                    <div class="col-md-6">
                    <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_bak_kondisi_text"  type="text" class="form-control" placeholder="Sebutkan"/>
                    <VValidate 
                        name="BaK Lainnya" 
                        v-model="isParent.row.arankkp_bak_kondisi_text" 
                        :rules="{required: 1, min: 2}"
                    />
                    </div>
                </template>
                </div>
            </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Proteksi dan Risiko</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                <div class="col-md-3">
                    <div>
                        <label for="perHamil">Status Mental</label>
                        <v-select placeholder="Pilih Status Mental" v-model="isParent.row.arankkp_status_mental" :options="isParent.Config.mr.ranapKPStatusMental" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    

                    <template v-if="isParent.row.arankkp_status_mental == 'L'">
                        <label class="mt-2" for="perHamil">Status Mental Lainnya<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_status_mental_text"  type="text" class="form-control" placeholder="Isi Status Mental Lainnya"/>
                        <VValidate 
                            name="Isi Status Mental Lainnya" 
                            v-model="isParent.row.arankkp_status_mental_text" 
                            :rules="{required: 1, min: 2}"
                        />
                    </template>
                    
                    <VValidate 
                        name="Status Mental" 
                        v-model="isParent.row.arankkp_status_mental" 
                        :rules="{required: 1}"
                    />
                </div>
                <!-- Muncul ketika pilih kejang-->
                <div class="col-md-3" v-if="isParent.row.arankkp_status_mental == 'Kejang'">
                    <div>
                    <label for="pdrStatusMental">Tipe dan Frekuensi</label>                        
                    <b-form-input v-model="isParent.row.arankkp_tipe_frekuensi" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Tipe dan Frekuensi" />
                    </div>
                </div>
                <!-- End-->
                </div>
            </div>
            <div class="card-body">
                <div class="font-weight-semibold mb-2">Pengkajian Restrain</div>
                <div class="row g-2">
                <div class="col-md-3">
                    <div>
                    <label for="pdrKajianRestrain">Pengkajian Restrain</label>
                    
                    <v-select placeholder="Pilih Pengkajian Restrain" v-model="isParent.row.arankkp_pengkajian_restrain" :options="isParent.Config.mr.isRestrain" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                    </div>
                    <!-- Muncul, Jika Pengkajian Restrain ada masalah-->
                    <div class="row mt-2" v-if="isParent.row.arankkp_pengkajian_restrain == 'Y'">
                    <div class="col-md-12">
                        <a href="javascript:;" @click="addRestrain=true" data-toggle="modal" data-target="#PengkajianRestrain"
                        class="btn btn-block btn-sm alpha-info border-info"><i
                            class="icon-plus2 mr-1"></i>Pengkajian Restrain</a>
                    </div>
                    </div>
                    <!-- End-->
                </div>
                <div class="col-md-4">
                    <div>
                        <label for="pdrKajianRestrain">Pernah Menggunakan Restrain Sebelumnya?</label>
                        <div>
                            <b-form-radio-group
                                :options="isParent.Config.mr.yesNoOpt"
                                v-model="isParent.row.arankkp_is_have_restrain"
                            />
                        </div>
                        
                        <VValidate 
                            name="Pernah Menggunakan Restrain" 
                            v-model="isParent.row.arankkp_is_have_restrain" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <div class="mt-2" v-if="isParent.row.arankkp_is_have_restrain == 'Y'">
                        <label for="">Dimana?</label>
                        <b-form-input v-model="isParent.row.arankkp_lokasi_restrain" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Lokasi Restrain Sebelumnya" />
                    </div>
                </div>
                <div class="col-md-4">
                    <b-form-checkbox
                        v-model="isParent.row.arankkp_is_edukasi_restrain"
                        value="Y"
                        :unchecked-value="null"
                        >
                        Edukasi dengan keluarga dan pasien untuk penggunaan restrain
                    </b-form-checkbox>
                    <!--
                    <VValidate 
                        name="Edukasi dengan keluarga" 
                        v-model="isParent.row.arankkp_is_edukasi_restrain" 
                        :rules="{required: 1}"
                    />
                    -->
                </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row g-2">
                <div class="col-md-3">

                    <div>
                        <label for="perHamil">Pengkajian Risiko Jatuh</label>
                        <v-select placeholder="Pilih Pengkajian Risiko Jatuh" v-model="isParent.row.arankkp_pengkajian_resiko_jatuh" :options="isParent.Config.mr.ranapKPRiwayatJatuh" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    

                    <template v-if="isParent.row.arankkp_pengkajian_resiko_jatuh == 'L'">
                        <label class="mt-2" for="perHamil">Pengkajian Risiko Jatuh Lainnya<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_pengkajian_resiko_jatuh_text"  type="text" class="form-control" placeholder="Isi Pengkajian Risiko Jatuh Lainnya"/>
                        <VValidate 
                            name="Isi Pengkajian Risiko Jatuh Lainnya" 
                            v-model="isParent.row.arankkp_pengkajian_resiko_jatuh_text" 
                            :rules="{required: 1, min: 2}"
                        />
                    </template>
                </div>
                </div>
            </div>
            </div>
        </div>
            

        
        <b-modal v-model="addRestrain" :title="'Asesmen Restrain'" size="xl" ok-only ok-title="Simpan">
            <div class="row">
                <div class="col-12">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                    </div>
                
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Tekanan Darah <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                <b-form-input :formatter="number" placeholder="Diastole" v-model="isParent.row.arankkp_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>      

                            <VValidate 
                                name="Tekanan Darah Min" 
                                v-model="isParent.row.arankkp_ttv_tekanan_darah_min" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_tekanan_darah_min)"
                            />
                            <VValidate 
                                name="Tekanan Darah Max" 
                                v-model="isParent.row.arankkp_ttv_tekanan_darah_max" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_tekanan_darah_max)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Ukuran Pupil </label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_ukuran_pupil"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mm/Ki</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Ukuran Pupil" 
                                v-model="isParent.row.arankkp_ttv_ukuran_pupil" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_ukuran_pupil)"
                            />
                            </div>

                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Nadi <span class="text-danger">*</span></label>
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <div class="input-group">
                                        <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                        <div class="input-group-append">
                                            <div style="width: 140px;">
                                            <v-select placeholder="Pilih Label" v-model="isParent.row.arankkp_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <VValidate 
                                    name="Nadi" 
                                    v-model="isParent.row.arankkp_ttv_nadi" 
                                    :rules="toValidate(isParent.mrValidation.arankkp_ttv_nadi)"
                                />
                                <VValidate 
                                    name="Label" 
                                    v-model="isParent.row.arankkp_ttv_label" 
                                    :rules="toValidate(isParent.mrValidation.arankkp_ttv_label)"
                                />
                            </div>
                        </div>
                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>GDA</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="alphanum" v-model="isParent.row.arankkp_ttv_gula_darah"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Gula Darah" 
                                v-model="isParent.row.arankkp_ttv_gula_darah" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_gula_darah)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Pernafasan<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_pernafasan"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                </div>
        
                                <VValidate 
                                    name="Pernafasan" 
                                    v-model="isParent.row.arankkp_ttv_pernafasan" 
                                    :rules="toValidate(isParent.mrValidation.arankkp_ttv_pernafasan)"
                                />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_spo2"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="SPO2" 
                                v-model="isParent.row.arankkp_ttv_spo2" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_spo2)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu<span class="text-danger">*</span></label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                                                       
                            <VValidate 
                                name="Suhu" 
                                v-model="isParent.row.arankkp_ttv_suhu" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_suhu)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input @input="hitungBMIRes()" :formatter="number" v-model="isParent.row.arankkp_ttv_weight"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                                                                       
                            <VValidate 
                                name="Berat Badan" 
                                v-model="isParent.row.arankkp_ttv_weight" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_weight)"
                            />
                            </div>
                        </div>

                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Tinggi</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input @input="hitungBMIRes()"  :formatter="number" v-model="isParent.row.arankkp_ttv_height"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Tinggi Badan" 
                                v-model="isParent.row.arankkp_ttv_height" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_height)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Lingkar Kepala</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_lingkar_kepala"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Lingkar Kepala" 
                                v-model="isParent.row.arankkp_ttv_lingkar_kepala" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_lingkar_kepala)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>BMI</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input disabled v-model="isParent.row.arankkp_ttv_bmi"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">m2</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                            <div class="form-group">
                            <label>Luas Permukaan Tubuh Anak</label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                            </div>
                            </div>
                        </div>
                        
                        <!--
                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Kesadaran</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <v-select placeholder="Pilih Kesadaran" v-model="isParent.row.arankkp_ttv_kesadaran" :options="isParent.mKesadaran" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <VValidate 
                                name="Kesadaran" 
                                v-model="isParent.row.arankkp_ttv_kesadaran" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_kesadaran)"
                            />
                            </div>
                        </div>
                        -->

                        <!--
                        <div class="col-md-3" v-if="!isParent.row.arankkp_ttv_bmi">
                            <div class="form-group">
                                <label>IMT</label>
                                <b-form-input v-model="isParent.row.arankkp_ttv_imt" type="text"
                                class="form-control" />
                            </div>
                        </div>
                        -->

                        </div>
                    </div>

                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Hasil Observasi</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-12">
                            <b-form-group>
                                <b-form-checkbox-group class="checkbox-block"
                                    v-model="isParent.row.arankkp_hasil_observasi"
                                    :options="isParent.Config.mr.ranapResHasilObservasi"
                                    name="hasil_observasi"
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Pertimbangan Klinis</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <b-form-group>
                                <b-form-checkbox-group class="checkbox-block"
                                    v-model="isParent.row.arankkp_hasil_klinis"
                                    :options="isParent.Config.mr.ranapResPertimbanganKlinis"
                                    name="hasil_klinis"
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Penilaian dan Order Dokter</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-12">
                            <b-form-group>
                                <b-form-checkbox-group class="checkbox-block"
                                    v-model="isParent.row.arankkp_hasil_penilaian"
                                    :options="isParent.Config.mr.ranapResPenilaianOrder"
                                    name="hasil_penilaian"
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                    <div class="card-header bg_head_panel">
                        <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                    </div>
                    <div class="card-body mt-2">
                        <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                            <th>Pengkajian</th>
                            <th>Nilai</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                            
                            <!-- ele pertama hardcode -->
                            <td>
                            <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_mata" name="arankkp_tingkat_kesadaran_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                            </td>

                            </tr>
                            <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                <tr v-if="k != 0" :key="k">
                                <td>
                                    <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_mata" name="arankkp_tingkat_kesadaran_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody>
                            <tr>
                            <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                            
                            <td>
                            <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_verbal" name="arankkp_tingkat_kesadaran_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                            </b-form-radio>
                            </td>
                            
                            </tr>
                            <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                <tr v-if="k != 0" :key="k">
                                <td>
                                    <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_verbal" name="arankkp_tingkat_kesadaran_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody>
                            <tr>
                            <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                            
                            <td>
                            <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_motorik" name="arankkp_tingkat_kesadaran_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                            </td>
                            
                            </tr>
                            <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                <tr v-if="k != 0" :key="k">
                                <td>
                                    <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_motorik" name="arankkp_tingkat_kesadaran_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                </td>
                                </tr>
                            </template>
                        </tbody>
                        <tfoot>
                            <tr>
                            <td class="table-info" colspan="9">
                                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotalRestrain}}</span>
                                <span v-if="tingkatKesadaranTotalRestrain >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                <span v-else-if="tingkatKesadaranTotalRestrain >= 12 && tingkatKesadaranTotalRestrain <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                <span v-else-if="tingkatKesadaranTotalRestrain >= 10 && tingkatKesadaranTotalRestrain <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                <span v-else-if="tingkatKesadaranTotalRestrain >= 7 && tingkatKesadaranTotalRestrain <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                <span v-else-if="tingkatKesadaranTotalRestrain >= 5 && tingkatKesadaranTotalRestrain <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                <span v-else-if="tingkatKesadaranTotalRestrain >= 4 && tingkatKesadaranTotalRestrain <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                            </td>
                            </tr>
                        </tfoot>
                        </table>
                    </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                    <div class="card-body table-success">
                        <b-form-checkbox
                            v-model="isParent.row.arankkp_kel_is_setuju"
                            value="Y"
                            :unchecked-value="null"
                            >
                            Keluarga sudah dijelaskan tentang restrain
                        </b-form-checkbox>
                        <VValidate 
                            name="DiJelaskan Restrain" 
                            v-model="isParent.row.arankkp_kel_is_setuju" 
                            :rules="{required: 1}"
                        />
                    </div>
                    </div>

                </div>
            </div>
        </b-modal>
            
    
        <b-modal v-model="isOpenTS" :title="'Isi Tingkat Kesadaran'" size="xl" ok-only ok-title="Simpan">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                <th>Pengkajian</th>
                                <th>Nilai</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                                
                                <!-- ele pertama hardcode -->
                                <td>
                                <b-form-radio @input="onInput($event, 'response-mata');isParent.autofill($event,'arankkp_tingkat_kesadaran_mata')" v-model="isParent.row.aranktk_response_mata" name="aranktk_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                                </td>

                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio @input="onInput($event, 'response-mata');isParent.autofill($event,'arankkp_tingkat_kesadaran_mata')" v-model="isParent.row.aranktk_response_mata" name="aranktk_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                                
                                <td>
                                <b-form-radio @input="onInput($event, 'response-verbal');isParent.autofill($event,'arankkp_tingkat_kesadaran_verbal')" v-model="isParent.row.aranktk_response_verbal" name="aranktk_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                                </b-form-radio>
                                </td>
                                
                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio @input="onInput($event, 'response-verbal');isParent.autofill($event,'arankkp_tingkat_kesadaran_verbal')" v-model="isParent.row.aranktk_response_verbal" name="aranktk_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                                
                                <td>
                                <b-form-radio @input="onInput($event, 'response-motorik');isParent.autofill($event,'arankkp_tingkat_kesadaran_motorik')" v-model="isParent.row.aranktk_response_motorik" name="aranktk_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                                </td>
                                
                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio @input="onInput($event, 'response-motorik');isParent.autofill($event,'arankkp_tingkat_kesadaran_motorik')" v-model="isParent.row.aranktk_response_motorik" name="aranktk_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                <td class="table-info" colspan="9">
                                    <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                    <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                    <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </td>
                                </tr>
                            </tfoot>
                            </table>

                            <!--
                            <VValidate 
                                name="Response Mata" 
                                v-model="isParent.row.aranktk_response_mata" 
                                :rules="toValidate(isParent.mrValidation.aranktk_response_mata)"
                                class="d-block"
                            />

                            
                            <VValidate 
                                name="Response Verbal" 
                                v-model="isParent.row.aranktk_response_verbal" 
                                :rules="toValidate(isParent.mrValidation.aranktk_response_verbal)"
                                class="d-block"
                            />
                            
                            <VValidate 
                                name="Response Motorik" 
                                v-model="isParent.row.aranktk_response_motorik" 
                                :rules="toValidate(isParent.mrValidation.aranktk_response_motorik)"
                                class="d-block"
                            />
                            -->


                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'

export default{
    extends: GlobalVue,
    components: {
      Datepicker
    },
    data(){
        return {
            addRestrain : false,
            isOpenTS: false,
            disabled: {
                'keluhan-utama': false,
                'riwayat-penyakit-dahulu': false,
                'catatan-dokter': false,
                'alergi-jenis': [],
                'alergi-name': [],
                'tekanan-darah-min': false,
                'tekanan-darah-max': false,
                'nadi': false,
                'nadi-label': false,
                'gula-darah': false,
                'pernafasa': false,
                'spo2': false,
                'suhu': false,
                'berat-badan': false,
                'tinggi': false,
                'lingkar-kepala': false,
                'permukaan-anak': false,
                'pupil': false,
            }
        }
    },
    mounted(){
        this.sockets.subscribe('collaborate_kajian-awal', data => {
            if(data.regId != this.$route.query.regId){
                return
            }

            if(data.type == 'set-disable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, true)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, true)
                }
                else{
                    this.$set(this.disabled, data.field, true)
                }
            }
            else if(data.type == 'set-enable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, false)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, false)
                }
                else{
                    this.$set(this.disabled, data.field, false)
                }
            }
            else if(data.type == 'set-value'){
                if(data.field == 'keluhan-utama'){
                    this.$set(this.isParent.row, 'arankaa_keluhan_utama', data.value)
                }
                else if(data.field == 'catatan-dokter'){
                    this.$set(this.isParent.row, 'arankaa_riwayat_penyakit_sekarang', data.value)
                }
                else if(data.field == 'riwayat-penyakit-dahulu'){
                    this.$set(this.isParent.row, 'arankaa_riwayat_penyakit', data.value)
                }
                else if(data.field == 'has-alergi'){
                    this.$set(this.isParent.row, 'arankaa_has_alergi', data.value)
                    if(!(this.isParent.row.arankaa_alergi||[]).length){
                        this.addAlergi()
                    }
                }
                else if(data.field == 'alergi-jenis'){
                    this.$set(this.isParent.row.arankaa_alergi[data.index], 'jenis', data.value)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.isParent.row.arankaa_alergi[data.index], 'name', data.value)
                }
                else if(data.field == 'tekanan-darah-min'){
                    this.$set(this.isParent.row, 'arankaa_ttv_tekanan_darah_min', data.value)
                }
                else if(data.field == 'tekanan-darah-max'){
                    this.$set(this.isParent.row, 'arankaa_ttv_tekanan_darah_max', data.value)
                }
                else if(data.field == 'nadi'){
                    this.$set(this.isParent.row, 'arankaa_ttv_nadi', data.value)
                }
                else if(data.field == 'nadi-label'){
                    this.$set(this.isParent.row, 'arankaa_ttv_label', data.value)
                }
                else if(data.field == 'gula-darah'){
                    this.$set(this.isParent.row, 'arankaa_ttv_gula_darah', data.value)
                }
                else if(data.field == 'pernafasan'){
                    this.$set(this.isParent.row, 'arankaa_ttv_pernafasan', data.value)
                }
                else if(data.field == 'spo2'){
                    this.$set(this.isParent.row, 'arankaa_ttv_spo2', data.value)
                }
                else if(data.field == 'suhu'){
                    this.$set(this.isParent.row, 'arankaa_ttv_suhu', data.value)
                }
                else if(data.field == 'berat-badan'){
                    this.$set(this.isParent.row, 'arankaa_ttv_weight', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'tinggi'){
                    this.$set(this.isParent.row, 'arankaa_ttv_height', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'lingkar-kepala'){
                    this.$set(this.isParent.row, 'arankaa_ttv_lingkar_kepala', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'permukaan-anak'){
                    this.$set(this.isParent.row, 'arankaa_ttv_luas_permukaan_anak', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'response-mata'){
                    this.$set(this.isParent.row, 'aranktk_response_mata', data.value)
                }
                else if(data.field == 'response-verbal'){
                    this.$set(this.isParent.row, 'aranktk_response_verbal', data.value)
                }
                else if(data.field == 'response-motorik'){
                    this.$set(this.isParent.row, 'aranktk_response_motorik', data.value)
                }
                else if(data.field == 'pupil'){
                    this.$set(this.isParent.row, 'arankaa_ttv_pupil', data.value)
                }
            }
            else if(data.type == 'delete-alergi'){
                this.isParent.row.arankaa_alergi.splice(data.index,1)
            }
            else if(data.type == 'add-alergi'){
                this.isParent.row.arankaa_alergi.push(data.value)
            }

            console.log(data)
        })
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }, 
        tingkatKesadaranTotalRestrain(){
            return (this.isParent.row.arankkp_tingkat_kesadaran_mata||0) + 
            (this.isParent.row.arankkp_tingkat_kesadaran_verbal||0) + 
            (this.isParent.row.arankkp_tingkat_kesadaran_motorik||0)
        },
        tingkatKesadaranTotal(){
            return (this.isParent.row.aranktk_response_mata||0) + 
            (this.isParent.row.aranktk_response_verbal||0) + 
            (this.isParent.row.aranktk_response_motorik||0)
        },
    },
    methods: {
        onFocus(v, field, index = false){
            const regId = this.$route.query.regId
            console.log(v)
            const payload = {
                to: 'kajian-awal',
                type: 'set-disable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onBlur(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-enable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onInput: _.debounce(function(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-value',
                field: field,
                regId: regId,
                value: v,
                index: index
            }

            this.$socket.emit('collaborate', payload)
        }, 500),

        addRiwayatPenggunaanObat(){
            this.isParent.row.arankaa_riwayat_penggunaan_obat.push({
                'nama': null,
                'dosis': null,
                'penggunaan': null,
            })
        },
        toValidate(val){
            return {...val}
        },
        hitungBMI(){
            if(this.isParent.row.arankaa_ttv_weight && this.isParent.row.arankaa_ttv_height){
                this.isParent.row.arankaa_ttv_bmi = this.isParent.row.arankaa_ttv_weight / ((this.isParent.row.arankaa_ttv_height/100) * (this.isParent.row.arankaa_ttv_height/100))
                this.isParent.row.arankaa_ttv_bmi = this.isParent.row.arankaa_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.arankaa_ttv_bmi = null 
            }
        },
        
        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.isParent.mAlergi = res.data.data            
          })
        }, 100),
        
        changeAlergi(e){
            if(!(this.isParent.row.arankaa_alergi||[]).length){
                this.addAlergi()
            }
        },
        
        addAlergi(){
            const data = {
                name : '',
                jenis : null,
            }
            if(!Array.isArray(this.isParent.row.arankaa_alergi)){
                this.$set(this.isParent.row, 'arankaa_alergi', [])
            }

            this.isParent.row.arankaa_alergi.push(data)
            this.addMasterAlergi()

            const payload = {
                to: 'kajian-awal',
                type: 'add-alergi',
                regId: this.$route.query.regId,
                value: data
            }

            this.$socket.emit('collaborate', payload)
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.isParent.row.arankaa_alergi||[]).length; i++){
                let newData = this.isParent.row.arankaa_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.isParent.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        
        removeAlergi(k){
            if(!Array.isArray(this.isParent.row.arankaa_alergi)){
                this.$set(this.isParent.row, 'arankaa_alergi', [])
            }

            this.isParent.row.arankaa_alergi.splice(k,1)
            const payload = {
                to: 'kajian-awal',
                type: 'delete-alergi',
                regId: this.$route.query.regId,
                index: k
            }

            this.$socket.emit('collaborate', payload)
        },
        
        inputPsikolog(e){
            if(this.isParent.row.arankaa_status_psikologi == 6){
                this.isParent.row.arankaa_status_psikologi_text = ''
            }
        },

        changeHPL() {
          let dateHPL = moment(this.isParent.row.arankaa_hpht, "DD-MM-YYYY").format('YYYY-M-D')
          let splDate = dateHPL.split("-")
          let day = splDate[2]
          let month = splDate[1]
          let year = splDate[0]

          day = +day + 7
          if (month == 1) {
            month = 10
          } else if (month == 2) {
            month = 11
          } else if (month == 3) {
            month = 12
          } else {
            month = +month - 3
          }
          year = +year + 1

          let daysOfMonth = moment(+year + "-" + month + "-01").endOf("month").format("D")
          daysOfMonth = +daysOfMonth

          let newDay

          if (day > daysOfMonth) {
            newDay = day - daysOfMonth
            month = month + 1
          } else {
            newDay = day
          }

          let newdateHPL = moment((newDay + '-' + month + '-' + year), "DD-MM-YYYY").format('YYYY-MM-DD')

          this.isParent.row.arankaa_hpl = newdateHPL
        },
        
        hitungBMIRes(){
            if(this.isParent.row.arankkp_ttv_weight && this.isParent.row.arankkp_ttv_height){
                this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_weight / this.isParent.row.arankkp_ttv_height
                this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.arankkp_ttv_bmi = null 
            }
        },
    },
    watch:{
        tingkatKesadaranTotalRestrain(v){
            setTimeout(()=>{
                this.isParent.row.arankkp_tingkat_kesadaran = v
            },250)
        },
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.isParent.row.aranktk_nilai_gcs = v
            },250)
        },
        
        // addRestrain(v){
        //     if(v){
        //         this.isParent.row.arankkp_ttv_tekanan_darah_min = this.isParent.row.arankkp_ttv_tekanan_darah_min ||this.isParent.row.arankaa_ttv_tekanan_darah_min
        //         this.isParent.row.arankkp_ttv_tekanan_darah_max = this.isParent.row.arankkp_ttv_tekanan_darah_max ||this.isParent.row.arankaa_ttv_tekanan_darah_max
        //         this.isParent.row.arankkp_ttv_ukuran_pupil = this.isParent.row.arankkp_ttv_ukuran_pupil ||this.isParent.row.arankaa_ttv_ukuran_pupil
        //         this.isParent.row.arankkp_ttv_nadi = this.isParent.row.arankkp_ttv_nadi ||this.isParent.row.arankaa_ttv_nadi
        //         this.isParent.row.arankkp_ttv_label = this.isParent.row.arankkp_ttv_label ||this.isParent.row.arankaa_ttv_label
        //         this.isParent.row.arankkp_ttv_gula_darah = this.isParent.row.arankkp_ttv_gula_darah ||this.isParent.row.arankaa_ttv_gula_darah
        //         this.isParent.row.arankkp_ttv_pernafasan = this.isParent.row.arankkp_ttv_pernafasan ||this.isParent.row.arankaa_ttv_pernafasan
        //         this.isParent.row.arankkp_ttv_spo2 = this.isParent.row.arankkp_ttv_spo2 ||this.isParent.row.arankaa_ttv_spo2
        //         this.isParent.row.arankkp_ttv_suhu = this.isParent.row.arankkp_ttv_suhu ||this.isParent.row.arankaa_ttv_suhu
        //         this.isParent.row.arankkp_ttv_weight = this.isParent.row.arankkp_ttv_weight ||this.isParent.row.arankaa_ttv_weight
        //         this.isParent.row.arankkp_ttv_height = this.isParent.row.arankkp_ttv_height ||this.isParent.row.arankaa_ttv_height
        //         this.isParent.row.arankkp_ttv_lingkar_kepala = this.isParent.row.arankkp_ttv_lingkar_kepala ||this.isParent.row.arankaa_ttv_lingkar_kepala
        //         this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_bmi ||this.isParent.row.arankaa_ttv_bmi
        //         this.isParent.row.arankkp_ttv_kesadaran = this.isParent.row.arankkp_ttv_kesadaran ||this.isParent.row.arankaa_ttv_kesadaran
        //         this.isParent.row.arankkp_ttv_luas_permukaan_anak = this.isParent.row.arankkp_ttv_luas_permukaan_anak ||this.isParent.row.arankaa_ttv_luas_permukaan_anak
        //         // autofill ttv
        //     }
        // }
    },

    destroyed(){
        this.sockets.unsubscribe('collaborate_kajian-awal')
    }
}

</script>