<template>
    <div>
        <div class="row gy-2">
            <div class="col-md-3">
                <div>
                <label for="kenyamananNyeri">Apakah Pasien merasakan nyeri?</label>
                <b-form-radio-group
                    :options="isParent.Config.mr.yesNoOpt"
                    v-model="isParent.row.arankkn_is_nyeri"
                />
                <VValidate 
                    name="Pasien Merasakan Nyeri" 
                    v-model="isParent.row.arankkn_is_nyeri" 
                    :rules="{required: 1}"
                />
                </div>
            </div>
        </div>

        <template v-if="isParent.row.arankkn_is_nyeri == 'Y'">
        <div class="row g-2 mt-2">
            <!-- Skala Nyeri jika pasien berusia lebih dari sama dengan 6 tahun -->
            <div class="col-12" v-if="isParent.row.ap_usia > 6">
                <div class="card">
                    <div class="card-header bg-info">
                        <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-md-auto">
                                <label for="perPain">Skala Nyeri<strong class="text-danger">*</strong></label>
                                <div>
                                    <div v-for="(v,k) in Config.mr.skalaNyeri" :key="k" :class="`pain-scale-radio ${v.class}`">
                                    <input type="radio" v-model="isParent.row.arankkn_skala_nyeri_value" name="perPainScale" :id="`perPainScale${v.text}`" :value="v.value" class="psr-input">
                                    <label :for="`perPainScale${v.text}`" class="psr-label">
                                        <span class="psr-label-content">{{v.text}}</span>
                                        <div class="psr-check-container"></div>
                                    </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="pain-scale no-pain" v-if="isParent.row.arankkn_skala_nyeri_value == 0">
                                    <img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                                    <strong>Tidak Ada Nyeri</strong>
                                </div>
                                <div class="pain-scale mild-pain" v-if="isParent.row.arankkn_skala_nyeri_value >= 1 && isParent.row.arankkn_skala_nyeri_value <= 3">
                                    <img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                                    <strong>Sedikit Nyeri</strong>
                                </div>
                                <div class="pain-scale moderate-pain" v-if="isParent.row.arankkn_skala_nyeri_value >= 4 && isParent.row.arankkn_skala_nyeri_value <= 5">
                                    <img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                                    <strong>Agak Mengganggu</strong>
                                </div>
                                <div class="pain-scale severe-pain" v-if="isParent.row.arankkn_skala_nyeri_value >= 6 && isParent.row.arankkn_skala_nyeri_value <= 7">
                                    <img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                                    <strong>Mengganggu Aktivitas</strong>
                                </div>
                                <div class="pain-scale worst-pain" v-if="isParent.row.arankkn_skala_nyeri_value >= 8 && isParent.row.arankkn_skala_nyeri_value <= 9">
                                    <img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                                    <strong>Sangat Mengganggu</strong>
                                </div>
                                <div class="pain-scale worst-pain" v-if="isParent.row.arankkn_skala_nyeri_value >= 10">
                                    <img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                                    <strong>Tak Tertahankan</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <VValidate 
                    name="Skala Nyeri" 
                    v-model="isParent.row.arankkn_skala_nyeri_value" 
                    :rules="{required: 1}"
                />
            </div>
            
            <!-- Skala Nyeri jika pasien berusia kurang dari 6 tahun -->
            <div class="col-12" v-else>

                <div class="card">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">FLACC Scale</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                        <label for="kidPainScale">Skala Nyeri</label>
                        <table class="table table-bordered pain-scale-table">
                            <thead>
                            <tr>
                                <th>Pengkajian</th>
                                <th>Nilai 0</th>
                                <th>Nilai 1</th>
                                <th>Nilai 2</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.row.arankkn_skala_nyeri_flacc||[])" :key="k">
                                <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                                <td v-for="(vc,kc) in v.configVal" :key="kc">
                                <div class="form-check form-check-inline align-middle">
                                <label class="form-check-label" :for="v.name+k+kc">
                                    <b-form-radio type="radio" :value="vc.value" v-model="isParent.row.arankkn_skala_nyeri_flacc[k]['value']" :id="v.name+k+kc" class="form-check-input-styled" />{{vc.text}}
                                </label>
                                </div>
                                
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr class="table-info">
                                <td colspan="4" class="text-info-700">
                                <span>SKALA: {{hitungSkala}} </span>
                                
                                <span v-if="hitungSkala == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                                <span v-else-if="hitungSkala >= 1 && hitungSkala < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                                <span v-else-if="hitungSkala >= 4 && hitungSkala < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        </div>
                    </div>
                </div>
                
                <VValidate 
                    name="Skala Nyeri" 
                    v-model="isParent.row.arankkn_skala_nyeri_value" 
                    :rules="{required: 1}"
                />
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik (Termasuk pemeriksan Neurologis dan
                        Muskoloskeletal)</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row gx-2">
                            <div class="col-lg-4">
                                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                <li class="nav-item" @click="changeTabAnat(k)"
                                    v-for="(v,k) in isParent.row.arankkn_pemeriksaan_fisik||[]" :key="k+'nav'">
                                    <a href="javascript:;" data-toggle="tab"
                                    :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>{{v.name||"(Anatomi Baru)"}}</span>
                                        <div>
                                        <i class="icon-chevron-right"></i>
                                        </div>
                                    </div>
                                    </a>
                                </li>
                                <li class="nav-item border-top">
                                    <a href="javascript:;" @click="addAnatomi"
                                    class="nav-link border-top bg-light">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="icon-plus2 mr-2"></i>Tambah Anatomi</span>
                                    </div>
                                    </a>
                                </li>
                                <li class="nav-item border-top">
                                    <a href="javascript:;" @click="deleteAnatomi(activeAnat)"
                                    class="nav-link border-top bg-light">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="icon-trash mr-2"></i>Hapus Anatomi</span>
                                    </div>
                                    </a>
                                </li>
                                </ul>
                            </div>
                            <div class="col-lg-8">
                                <div class="tab-content">
                                <div v-for="(v,k) in isParent.row.arankkn_pemeriksaan_fisik||[]" :key="k+'navAnat'"
                                    :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                    <div class="form-group">
                                    <label for="">Jenis Anatomi<strong
                                        class="text-danger">*</strong></label>
                                    <div class="input-group flex-nowrap">
                                        <v-select class="flex-fill" placeholder="Pilih Pemeriksaan Fisik"
                                        @input="selectStatusLokalis($event,k)" v-model="v.type"
                                        :disabled="!v.isEdit"
                                        :options="getStatusLokalis(isParent.row.arankkn_pemeriksaan_fisik,k)" label="text"
                                        :reduce="v=>v.value">
                                        </v-select>

                                        <div class="input-group-append">
                                        <button @click="editAnatomi(k)" type="button"
                                            class="btn btn-info btn-labeled btn-labeled-left">
                                            <b><i class="icon-pencil"></i></b>
                                            Edit
                                        </button>
                                        </div>

                                        <div class="input-group-append">
                                        <button @click="saveAnatomi(k)" type="button"
                                            class="btn btn-success btn-labeled btn-labeled-left">
                                            <b><i class="icon-checkmark"></i></b>
                                            Simpan
                                        </button>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                    <div class="position-relative" :id="`anatomiNyeri${k}`"
                                        @click.stop.prevent>
                                        <div class="body_wrapper">
                                        <img :src="assetLocal(v.image)" class="img-fluid w-100"
                                            alt="">
                                        </div>
                                        <div class="anatomy-info" v-for="(v1,k1) in v.data||[]"
                                        :key="k1+'body'" :style="`top:${v1.y}%;left:${v1.x}%`">
                                        <a href="javascript:;" @click="openAnat(k1,k)" class="ai-point"
                                            v-b-tooltip.hover :html="true" :title="v1.value"></a>
                                        <div class="anatomy-info-input" :class="{
                                    'd-block': v1.isOpen,
                                    'reversed': v1.isReversed
                                    }" :style="v1.isReversed ? 'transform: translateX('+v1.reverseCount+')' : ''"
                                            @click.stop.prevent>
                                            <textarea v-model="v1.value" name="anatomyInput" id="anatomyInput"
                                            rows="2" class="form-control form-control-sm"></textarea>
                                            <small class="text-muted"><i class="icon-info22"></i> Keluhan
                                            harus diisi</small>

                                            <div class="mt-2 text-right">
                                            <a href="javascript:;" class="btn btn-sm btn-danger"
                                                @click.prevent.stop="removeAnat(k1,k)">Hapus</a>
                                            <a href="javascript:;" @click.prevent.stop="closeAnat(k1,k)"
                                                class="btn btn-sm btn-success">Simpan</a>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <span v-if="!(isParent.row.arankkn_pemeriksaan_fisik||[]).length">Belum Ada Data Anatomi
                                    (Klik Tambah Untuk Menambahkan)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="row mt-2">
            <div class="col-md-3 mb-2">
            <div>
                <label for="painFrequency">Frekuensi Nyeri <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.arankkn_frekuensi_nyeri" :options="isParent.Config.mr.ranapKNFrNyeri" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                
                <VValidate 
                    name="Frekuensi Nyeri" 
                    v-model="isParent.row.arankkn_frekuensi_nyeri" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
            <div class="col-md-3 mb-2">
            <div>
                <label for="painDuration">Lama Nyeri <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <b-form-input v-model="isParent.row.arankkn_lama_nyeri" :formatter="normalText" type="text" class="form-control"/>

                <VValidate 
                    name="Lama Nyeri" 
                    v-model="isParent.row.arankkn_lama_nyeri" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
            <div class="col-md-6 mb-2">
            <div>
                <label for="painSpread">Menjalar? <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <div class="form-row">
                <div class="col-md-4">
                    <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.arankkn_menjalar" :options="isParent.Config.mr.ranapKNMenjalar" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
                <div class="col-md-8" v-if="isParent.row.arankkn_menjalar == 'Y'">
                    <b-form-input v-model="isParent.row.arankkn_menjalar_text" :formatter="normalText" type="text" class="form-control"/>
                </div>
                </div>
                
                <VValidate 
                    name="Menjalar" 
                    v-model="isParent.row.arankkn_menjalar" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
            <div class="col-md-3">
            <div>
                <label for="painQuality">Kualitas Nyeri <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.arankkn_kualitas_nyeri" :options="isParent.Config.mr.ranapKNKualitasNyeri" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                
                <VValidate 
                    name="Kualitas Nyeri" 
                    v-model="isParent.row.arankkn_kualitas_nyeri" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
            <div class="col-md-3">
            <div>
                <label for="painTrigger">Faktor pemicu/yang memperberat <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <textarea v-model="isParent.row.arankkn_faktor_pemicu" rows="2" class="form-control form-control-sm"></textarea>

                <VValidate 
                    name="Faktor pemicu/yang memperberat" 
                    v-model="isParent.row.arankkn_faktor_pemicu" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
            <div class="col-md-3">
            <div>
                <label for="painReduce">Faktor yang mengurangi <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <textarea v-model="isParent.row.arankkn_faktor_mengurangi" rows="2" class="form-control form-control-sm"></textarea>
                
                <VValidate 
                    name="Faktor yang mengurangi" 
                    v-model="isParent.row.arankkn_faktor_mengurangi" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
            <div class="col-md-3">
            <div>
                <label for="painQuality">Tindak Lanjut Skala Nyeri <small v-if="isParent.row.arankkn_is_nyeri == 'Y'" class="text-danger"> *</small></label>
                <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.arankkn_tindak_lanjut_skala_nyeri" :options="isParent.Config.mr.ranapKNTindakLanjut" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                
                <VValidate 
                    name="Tindak Lanjut Skala Nyeri" 
                    v-model="isParent.row.arankkn_tindak_lanjut_skala_nyeri" 
                    :rules="{required: 1}"
                />
            </div>
            </div>
        </div>
        </template>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
    extends: GlobalVue,
    data(){
        return {
            activeAnat: 0,
        }
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        
        hitungSkala(){
            let value = 0
            if(this.isParent.row.ap_usia <= 6){
                for(let i = 0; i < (this.isParent.row.arankkn_skala_nyeri_flacc||[]).length; i++){
                    value += this.isParent.row.arankkn_skala_nyeri_flacc[i]['value']
                }   
            }else{
                value += this.isParent.row.arankkn_skala_nyeri_value
            }
            return value
        },  
    },
    mounted() {
        setTimeout(() => {
            this.setPosisionNyeri()
        },1500)
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        editAnatomi(k) {
            this.isParent.row.arankkn_pemeriksaan_fisik[k]['isEdit'] = true
        },
        saveAnatomi(k) {
            this.isParent.row.arankkn_pemeriksaan_fisik[k]['isEdit'] = false
        },
        changeTabAnat(k, isAdd = false) {
            this.activeAnat = k
            setTimeout(() => {
            if (isAdd) {
                this.setPosisionNyeri()
            }
            }, 100)
        },
        deleteAnatomi(key) {
            this.isParent.row.arankkn_pemeriksaan_fisik.splice(key, 1)
            this.activeAnat = (this.isParent.row.arankkn_pemeriksaan_fisik || []).length - 1
        },
        addAnatomi() {
            this.isParent.row.arankkn_pemeriksaan_fisik = this.isParent.row.arankkn_pemeriksaan_fisik ? this.isParent.row.arankkn_pemeriksaan_fisik : []
            this.isParent.row.arankkn_pemeriksaan_fisik.push({
                name: '',
                type: null,
                image: '',
                isEdit: true,
                data: [],
            })
            console.log("add anat")
            this.activeAnat = (this.isParent.row.arankkn_pemeriksaan_fisik || []).length - 1
        },
        selectStatusLokalis(e, k) {
            let idx = this.isParent.Config.mr.configStatusLokalis.findIndex(x => x.value == e)
            if (idx !== -1) {
                let data = this.isParent.Config.mr.configStatusLokalis[idx]
                this.isParent.row.arankkn_pemeriksaan_fisik[k]['name'] = data.text
                if (this.isParent.row.ap_gender == 2) {
                    this.isParent.row.arankkn_pemeriksaan_fisik[k]['image'] = data.image2
                } else {
                    this.isParent.row.arankkn_pemeriksaan_fisik[k]['image'] = data.image
                }
            }

            this.isParent.row.arankkn_pemeriksaan_fisik[this.activeAnat]['data'] = []

            if (!$("#anatomiNyeri" + this.activeAnat).length) {
                this.changeTabAnat(k, true)
            } else {
                this.changeTabAnat(k)
            }
        },

        getStatusLokalis(data, k) {
            const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.type
            })
            return _.filter(this.isParent.Config.mr.configStatusLokalis, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },

        removeAnat(k, kp) {
            this.isParent.row.arankkn_pemeriksaan_fisik[kp]['data'].splice(k, 1)
        },
        closeAnat(k, kp) {
            if (this.isParent.row.arankkn_pemeriksaan_fisik[kp]['data'][k]['value']) {
            this.isParent.row.arankkn_pemeriksaan_fisik[kp]['data'][k]['isOpen'] = false
            this.isParent.row.arankkn_pemeriksaan_fisik[kp]['data'][k]['y'] += (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
            }
        },
        openAnat(k, kp) {
            this.isParent.row.arankkn_pemeriksaan_fisik[kp]['data'][k]['y'] -= (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
            this.isParent.row.arankkn_pemeriksaan_fisik[kp]['data'][k]['isOpen'] = true
        },
        setPosisionNyeri() {
            let posX = false
            let posY = false
            let self = this

            $("body").mousemove(function (e) {
                if (!$("body").hasClass("active")) $("body").addClass("active")
                if ($("#anatomiNyeri" + self.activeAnat).length) {
                    posX = parseInt(e.pageX - ($("#anatomiNyeri" + self.activeAnat).offset().left + 8))
                    posY = parseInt(e.pageY - ($("#anatomiNyeri" + self.activeAnat).offset().top + 52))
                }
            })

            console.log("init")

            $('#anatomiNyeri' + self.activeAnat).on('click', function (e) {
            e.stopPropagation()
            let index = self.isParent.row.arankkn_pemeriksaan_fisik[self.activeAnat]['data'].findIndex(x => x.isOpen == true)

            if (self.isParent.row.arankkn_pemeriksaan_fisik[self.activeAnat]['isEdit']) {
                if (index !== -1) {
                     self.isParent.row.arankkn_pemeriksaan_fisik[self.activeAnat]['data'].splice(index, 1)
                } else {
                    self.isParent.row.arankkn_pemeriksaan_fisik[self.activeAnat]['data'].push({
                    x: (posX / $(this).width()) * 100,
                    y: (posY / $(this).height()) * 100,
                    value: '',
                    isOpen: true,
                    isReversed: posX > 300 ? true : false,
                    reverseCount: `-111%`
                    // reverseCount: `-${((posX - 300) / 2) - 16 + 300}px`
                })
                }
            }
            })
        },
    },
    watch:{
        hitungSkala(v){
            setTimeout(()=>{
                this.isParent.row.arankkn_skala_nyeri_value = v
            },250)
        },
    }
    

}

</script>
