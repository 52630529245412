<template>
  <div class="row">
    <div class="col-6 mb-3">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Airways</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <b-form-group>
                  <b-form-checkbox-group class="checkbox-block"
                      v-model="isParent.row.arankab_airways"
                      :options="isParent.mAspekBioAirways"
                  ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Breathing</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <b-form-group>
                  <b-form-checkbox-group class="checkbox-block"
                      v-model="isParent.row.arankab_breathing"
                      :options="isParent.mAspekBreathing"
                  ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Circulation</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="Akral">Akral<strong class="text-danger">*</strong></label>
                <b-form-radio-group
                  :options="Config.mr.configAkaral"
                  v-model="isParent.row.arankab_cir_akral"
                />
                <VValidate 
                    name="Akral" 
                    v-model="isParent.row.arankab_cir_akral" 
                    :rules="toValidate(isParent.mrValidation.arankab_cir_akral)"
                />
              </div>
              <div class="form-group">
                <label for="Pucat">Pucat<strong class="text-danger">*</strong></label>
                <b-form-radio-group
                  :options="Config.mr.configPucatDingin"
                  v-model="isParent.row.arankab_cir_pucat"
                />
                <VValidate 
                    name="Pucat" 
                    v-model="isParent.row.arankab_cir_pucat" 
                    :rules="toValidate(isParent.mrValidation.arankab_cir_pucat)"
                />
              </div>
              <div class="form-group">
                <label for="Sianosi">Sianosi <strong class="text-danger">*</strong>
                </label>
                <b-form-radio-group
                  :options="Config.mr.configSianosi"
                  v-model="isParent.row.arankab_cir_sianosi"
                />
                <VValidate 
                    name="Sianosi" 
                    v-model="isParent.row.arankab_cir_sianosi" 
                    :rules="toValidate(isParent.mrValidation.arankab_cir_sianosi)"
                />
              </div>
              <div class="form-group">
                <label for="Kelembapan">Kelembapan kulit <strong class="text-danger">*</strong>
                </label>
                <b-form-radio-group
                  :options="Config.mr.configKelembapanKulit"
                  v-model="isParent.row.arankab_cir_kelembapan_kulit"
                />
                <VValidate 
                    name="Kelembapan Kulit" 
                    v-model="isParent.row.arankab_cir_kelembapan_kulit" 
                    :rules="toValidate(isParent.mrValidation.arankab_cir_kelembapan_kulit)"
                />
              </div>
              <div class="form-group">
                <label for="Turgor">Turgor<strong class="text-danger">*</strong></label>
                <b-form-radio-group
                  :options="Config.mr.configTurgor"
                  v-model="isParent.row.arankab_cir_turgor"
                />
                <VValidate 
                    name="Turgor" 
                    v-model="isParent.row.arankab_cir_turgor" 
                    :rules="toValidate(isParent.mrValidation.arankab_cir_turgor)"
                />
              </div>
              <div class="form-group">
                <label for="Perdarahan">Perdarahan</label>
                <div class="input-group">
                  <b-form-input v-model="isParent.row.arankab_pendarahan" type="text" class="form-control" autocomplete="off" />
                  <div class="input-group-append"><span class="input-group-text">cc</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="Perdarahan">Lain-lain</label>
                  <b-form-input v-model="isParent.row.arankab_lainnya" type="text" class="form-control" autocomplete="off" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Hidrasi</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <b-form-group>
                <b-form-checkbox-group class="checkbox-block"
                    v-model="isParent.row.arankab_hidrasi"
                    :options="isParent.mAspekHidrasi"
                ></b-form-checkbox-group>
                
                <b-form-checkbox
                  v-model="isParent.row.arankab_is_kehilangan_cairan"
                  value="Y"
                  unchecked-value="N"
                  > Kehilangan cairan melalui
                </b-form-checkbox>

                <div v-if="isParent.row.arankab_is_kehilangan_cairan == 'Y'">
                  <div class="mb-2">
                    <b-form-input v-model="isParent.row.arankab_is_kehilangan_cairan_text" type="text" class="form-control" autocomplete="off" />
                    <VValidate 
                      name="Kehilangan Cairan" 
                      v-model="isParent.row.arankab_is_kehilangan_cairan_text" 
                      :rules="{required:1}"
                    />
                  </div>
                </div>

                
                <b-form-checkbox
                  v-model="isParent.row.arankab_is_lainnya"
                  value="Y"
                  unchecked-value="N"
                  > Lain-lain
                </b-form-checkbox>
                
                <div v-if="isParent.row.arankab_is_lainnya == 'Y'">
                  <div class="mb-2">
                    <b-form-input v-model="isParent.row.arankab_is_lainnya_text" type="text" class="form-control" autocomplete="off" />
                    <VValidate 
                        name="Lain-lain" 
                        v-model="isParent.row.arankab_is_lainnya_text" 
                        :rules="{required:1}"
                    />
                  </div>
                </div>


              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Nutrisi</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <b-form-group>
                <b-form-checkbox-group class="checkbox-block"
                    v-model="isParent.row.arankab_nutrisi"
                    :options="isParent.mAspekNutrisi"
                ></b-form-checkbox-group>
              </b-form-group>
              <div v-if="(isParent.row.arankab_nutrisi||[]).indexOf('Lain-lain') != -1">
                <b-form-input v-model="isParent.row.arankab_nutrisi_lainnya" type="text" class="form-control" autocomplete="off" />
                <VValidate 
                  v-if="(isParent.row.arankab_nutrisi||[]).indexOf('Lain-lain') != -1"
                  name="Nutrisi Lainnya" 
                  v-model="isParent.row.arankab_nutrisi_lainnya" 
                  :rules="{required:1}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Aktivitas dan Latihan</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <b-form-checkbox-group class="checkbox-inline"
                  v-model="isParent.row.arankab_aktivitas_latihan"
                  :options="isParent.mAspekAktivitas"
              ></b-form-checkbox-group>
              <b-form-input placeholder="Aktivitas dan Latihan Lainnya" style="width: 270px;" v-if="(isParent.row.arankab_aktivitas_latihan||[]).indexOf('Lain-lain') != -1" v-model="isParent.row.arankab_aktivitas_lathian_text" type="text" class="form-control" autocomplete="off" />
              <VValidate 
                v-if="(isParent.row.arankab_aktivitas_latihan||[]).indexOf('Lain-lain') != -1"
                name="Aktivitas dan Latihan Lainnya" 
                v-model="isParent.row.arankab_aktivitas_lathian_text" 
                :rules="{required:1}"
              />
            </div>
            <div class="col-md-12 mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Kekuatan otot</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-sm table-bordered ">
                        <tr>
                          <th>
                            <div class="result_tab">
                              <h4>Kanan Atas</h4>
                              <p>{{isParent.row.arankab_kekuatan_otot_kanan_atas||"0"}}</p>
                              <VValidate 
                                name="Kanan Atas" 
                                v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" 
                                :rules="{required:1}"
                              />
                            </div>
                          </th>
                          <th>
                            <div class="result_tab">
                              <h4>Kiri Atas</h4>
                              <p>{{isParent.row.arankab_kekuatan_otot_kiri_atas||"0"}}</p>
                              <VValidate 
                                name="Kiri Atas" 
                                v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" 
                                :rules="{required:1}"
                              />
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <div class="result_tab">
                              <h4>Kanan Bawah</h4>
                              <p>{{isParent.row.arankab_kekuatan_otot_kanan_bawah||"0"}}</p>
                              <VValidate 
                                name="Kanan Bawah" 
                                v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" 
                                :rules="{required:1}"
                              />
                            </div>
                          </th>
                          <th>
                            <div class="result_tab">
                              <h4>Kiri Bawah</h4>
                              <p>{{isParent.row.arankab_kekuatan_otot_kiri_bawah||"0"}}</p>
                              <VValidate 
                                name="Kiri Bawah" 
                                v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" 
                                :rules="{required:1}"
                              />
                            </div>
                          </th>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td class="table-secondary" rowspan="3">EKTERMITAS
                            </td>
                            <td class="text-center table-secondary" colspan="6">EKTERMITAS
                            </td>
                          </tr>
                          <tr>
                            <th>Normal</th>
                            <th>Mampu melakukan gerakan normal,tapi tidak bisa
                              melawan tahanan maksimal
                              pemeriksa
                            </th>
                            <th>Mampu melakukan gerakan mengangkat
                              ektermitas/badan,tapi tidak bisa
                              melawan tahanan sedang
                            </th>
                            <th>Mampu melakukan gerakan dua sendi atau
                              lebih,tidak bisa melawan tahanan
                              minimal
                            </th>
                            <th>Hanya bisa menggerakan ujung jari</th>
                            <th>Tidak bisa menggerakan sama sekali</th>
                          </tr>
                          <tr class="text-center">
                            <td class="table-success">5</td>
                            <td class="table-info">4</td>
                            <td class="table-warning">3</td>
                            <td class="table-danger">2</td>
                            <td class="table-yellow">1</td>
                            <td class="table-red">0</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>KANAN ATAS</td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" name="kanan_atas" :value="5"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" name="kanan_atas" :value="4"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" name="kanan_atas" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" name="kanan_atas" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" name="kanan_atas" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_atas" name="kanan_atas" :value="0"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>KANAN BAWAH</td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" name="kanan_bawah" :value="5"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" name="kanan_bawah" :value="4"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" name="kanan_bawah" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" name="kanan_bawah" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" name="kanan_bawah" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kanan_bawah" name="kanan_bawah" :value="0"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>KIRI ATAS</td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" name="kiri_atas" :value="5"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" name="kiri_atas" :value="4"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" name="kiri_atas" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" name="kiri_atas" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" name="kiri_atas" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_atas" name="kiri_atas" :value="0"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>KIRI BAWAH
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" name="kiri_bawah" :value="5"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" name="kiri_bawah" :value="4"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" name="kiri_bawah" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" name="kiri_bawah" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" name="kiri_bawah" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_kekuatan_otot_kiri_bawah" name="kiri_bawah" :value="0"></b-form-radio>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Paska Operasi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group>
                          <b-form-checkbox-group class="checkbox-block"
                              v-model="isParent.row.arankab_paksa_operasi"
                              :options="isParent.mAspekPaksaOperasi"
                          ></b-form-checkbox-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Terpasang alat invasif
                  </h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group>
                          <b-form-checkbox-group class="checkbox-block"
                              v-model="isParent.row.arankab_alat_invasif"
                              :options="isParent.mAspekAlatInvasif"
                          ></b-form-checkbox-group>
                      </b-form-group>
                              
                      <div v-if="(isParent.row.arankab_alat_invasif||[]).indexOf('Lain-lain') != -1">
                        <b-form-input v-model="isParent.row.arankab_alat_invasif_text" type="text" class="form-control" autocomplete="off" />
                        <VValidate 
                          name="Alat Invasif Lainnya" 
                          v-model="isParent.row.arankab_alat_invasif_text" 
                          :rules="{required:1}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Skor Aktivitas</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td class="table-secondary" rowspan="3">EKTERMITAS
                            </td>
                            <td class="text-center table-secondary" colspan="5">SKOR
                              AKTIVITAS DAN
                              LATIHAN
                            </td>
                          </tr>
                          <tr>
                            <th>Mandiri</th>
                            <th>Dibantu sebagian</th>
                            <th>Dibantu orang lain</th>
                            <th>Dibantu orang dan peralatan</th>
                            <th>Ketergantungan/ tidak mampu</th>
                          </tr>
                          <tr class="text-center">
                            <td class="table-success">0</td>
                            <td class="table-info">1</td>
                            <td class="table-warning">2</td>
                            <td class="table-danger">3</td>
                            <td class="table-yellow">4</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mandi</td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mandi" name="eks_mandi" :value="0"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mandi" name="eks_mandi" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mandi" name="eks_mandi" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mandi" name="eks_mandi" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mandi" name="eks_mandi" :value="4"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>Berpakaian</td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_berpakaian" name="eks_berpakaian" :value="0"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_berpakaian" name="eks_berpakaian" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_berpakaian" name="eks_berpakaian" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_berpakaian" name="eks_berpakaian" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_berpakaian" name="eks_berpakaian" :value="4"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>Makan / Minum</td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_makan" name="eks_makan" :value="0"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_makan" name="eks_makan" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_makan" name="eks_makan" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_makan" name="eks_makan" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_makan" name="eks_makan" :value="4"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>Eliminasi
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_eliminasi" name="eks_eliminasi" :value="0"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_eliminasi" name="eks_eliminasi" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_eliminasi" name="eks_eliminasi" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_eliminasi" name="eks_eliminasi" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_eliminasi" name="eks_eliminasi" :value="4"></b-form-radio>
                            </td>
                          </tr>
                          <tr>
                            <td>Mobilisasi di TT
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mobilisasi" name="eks_mobilisasi" :value="0"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mobilisasi" name="eks_mobilisasi" :value="1"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mobilisasi" name="eks_mobilisasi" :value="2"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mobilisasi" name="eks_mobilisasi" :value="3"></b-form-radio>
                            </td>
                            <td class="text-center">
                              <b-form-radio v-model="isParent.row.arankab_eks_mobilisasi" name="eks_mobilisasi" :value="4"></b-form-radio>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      
                      <div class="mt-2"></div>      
                      
                      <VValidate 
                          name="Mandi" 
                          v-model="isParent.row.arankab_eks_mandi" 
                          :rules="{required: 1}"
                          class="d-block"
                      />
                      <VValidate 
                          name="Berpakaian" 
                          v-model="isParent.row.arankab_eks_berpakaian" 
                          :rules="{required: 1}"
                          class="d-block"
                      />
                      <VValidate 
                          name="Makan" 
                          v-model="isParent.row.arankab_eks_makan" 
                          :rules="{required: 1}"
                          class="d-block"
                      />
                      <VValidate 
                          name="Eliminasi" 
                          v-model="isParent.row.arankab_eks_eliminasi" 
                          :rules="{required: 1}"
                          class="d-block"
                      />
                      <VValidate 
                          name="Mobilisasi" 
                          v-model="isParent.row.arankab_eks_mobilisasi" 
                          :rules="{required: 1}"
                          class="d-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  methods: {
    toValidate(val){
      return {...val}
    },
  },
  computed: {
    isParent(){
      return this.$parent.$parent.$parent.$parent
    },
  },
  watch:{
    tingkatKesadaranTotal(v){
      setTimeout(()=>{
          this.isParent.row.aranktk_nilai_gcs = v
      },250)
    },
  }
}
</script>